enum BPColors {
  PrimaryMain = "#141414",
  PrimaryLight = "#696B70",
  SecondaryMain = "#E8EBF1",
  SecondaryLight = "#E8F0FE",
  ButtonHover = "#262626",
  ButtonDisabled = "#2E2E2E",
  AlertErrorBackground = "#E87C04",
  AlertSuccessBackground = "#4DB404",
  Error = "#CF222E",
  White = "#FFFFFF",
  Black = "#000000",
}
export default BPColors;

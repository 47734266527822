import React from "react";

import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";

const TermsOfServiceText = () => {
  return (
    <Box marginY="1.5rem">
      <Typography fontSize="0.875rem">
        By clicking this button you agree to Beatports’s{" "}
        <Link
          href="https://support.beatport.com/hc/en-us/articles/4414997837716-Terms-and-Conditions"
          target="_blank"
        >
          {" "}
          Terms of Use and Privacy{" "}
        </Link>{" "}
        and{" "}
        <Link
          href="https://support.beatport.com/hc/en-us/articles/4412316093588--Privacy-and-Cookie-Policy"
          target="_blank"
        >
          {" "}
          Cookies Policy{" "}
        </Link>
      </Typography>
    </Box>
  );
};

export default TermsOfServiceText;

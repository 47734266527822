import React from "react";

import { BrandFooter } from "./BrandFooter";
import PageCard from "./PageCard";
import PrimaryButton from "./PrimaryButton";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";

interface OwnProps {
  onResendNewLinkClick: () => void;
}

const InvalidVerificationLink = ({ onResendNewLinkClick }: OwnProps) => {
  return (
    <PageCard>
      <Box alignItems="center">
        <Typography component="h1" variant="h3" textAlign="center">
          This link expired
        </Typography>
        <PrimaryButton
          type="submit"
          label="Request New Link"
          onClick={onResendNewLinkClick}
          sx={{ marginTop: "2rem" }}
        />
      </Box>
      <BrandFooter />
    </PageCard>
  );
};

export default InvalidVerificationLink;

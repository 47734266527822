import React from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "../bp-ui/components/Box";
import Typography from "../bp-ui/components/Typography";
import { BrandFooter } from "../components/shared/BrandFooter";
import { BrandLogo } from "../components/shared/BrandLogo";
import LoadingContent from "../components/shared/LoadingContent";
import PageCard from "../components/shared/PageCard";
import PrimaryButton from "../components/shared/PrimaryButton";
import { invalidateQueriesAndNavigateTo } from "../util/navigation";
import Pages from "../util/pages";
import useAuthCurrentUserQuery from "../util/useAuthCurrentUserQuery";

const UpdateEmailNotificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const currentUser = useAuthCurrentUserQuery(navigate, location, queryClient);

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  if (currentUser?.email != null && currentUser.email != "") {
    navigateTo(Pages.settings);
  }

  return (
    <>
      {!currentUser ? (
        <LoadingContent />
      ) : (
        <PageCard>
          <Box>
            <BrandLogo />
            <Typography
              component="h1"
              variant="h3"
              sx={{
                lineHeight: "2.5rem",
                marginBottom: "1rem",
              }}
            >
              Update your email
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.5rem",
              }}
            >
              There is an issue with your email address, please update below.
            </Typography>
          </Box>
          <PrimaryButton
            type="submit"
            label="Go to email settings"
            sx={{ marginTop: "1.5rem", textTransform: "none" }}
            formNoValidate={true}
            onClick={() => navigateTo(Pages.emailEdit)}
          />
          <BrandFooter />
        </PageCard>
      )}
    </>
  );
};

export default UpdateEmailNotificationPage;

import React from "react";

import Button from "../../bp-ui/components/Button";
import Spinner from "../../bp-ui/icons/Spinner";
import { CustomSx } from "../../models/custom-sx";

type ButtonType = "submit" | "reset" | "button" | undefined;

interface OwnProps {
  type: ButtonType;
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  sx?: CustomSx;
  formNoValidate?: boolean;
}

const PrimaryButton = ({
  type,
  label,
  isDisabled,
  isLoading,
  onClick,
  sx,
  formNoValidate,
}: OwnProps) => {
  return (
    <Button
      type={type}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      variant="contained"
      color="primary"
      disableElevation={true}
      disableTouchRipple={true}
      sx={{
        height: "3.25rem",
        fontSize: "1rem",
        ...sx,
      }}
      fullWidth
      formNoValidate={formNoValidate}
    >
      {isLoading ? <Spinner /> : <span>{label}</span>}
    </Button>
  );
};

export default PrimaryButton;

import { useQuery } from "@tanstack/react-query";

import { fetchUserFromResetPasswordToken } from "../api/auth";

export const useUserFromResetPasswordToken = (token: string) =>
  useQuery({
    queryKey: ["user-from-password-reset-token", token],
    queryFn: () => fetchUserFromResetPasswordToken(token),
    retry: 0,
  });

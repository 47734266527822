import React from "react";

import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  onClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void | (() => void);
  customSx?: CustomSx;
  text: string;
}

const TextAction = ({ onClick, customSx, text }: OwnProps) => {
  return (
    <Box sx={customSx}>
      <Link>
        <Typography
          sx={{ fontWeight: "400", "&:hover": { fontWeight: "500" } }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </Link>
    </Box>
  );
};

export default TextAction;

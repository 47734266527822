import React, { useState } from "react";

import { useMutation } from "@tanstack/react-query";

import CopyToClipboardButton from "./CopyToClipboardButton";
import { resetPasswordLink } from "../../api/control";
import Box from "../../bp-ui/components/Box";
import Button from "../../bp-ui/components/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "../../bp-ui/components/Dialog";

interface OwnProps {
  userId: number;
  setError: () => void;
}

const ResetPasswordLink = ({ userId, setError }: OwnProps) => {
  const [link, setLink] = useState("");
  const [resetPasswordLinkModalOpen, setResetPasswordLinkModalOpen] =
    useState(false);

  const mutateResetPasswordLink = useMutation({
    mutationFn: async () => {
      return resetPasswordLink({
        userId,
      });
    },
    onSuccess: (data) => {
      setLink(data.resetPasswordLink);
      handleResetPasswordLinkModal(true);
    },
    onError: () => {
      setError();
      handleResetPasswordLinkModal(false);
    },
  });

  const getResetPasswordLink = () => {
    mutateResetPasswordLink.mutate();
  };

  const handleResetPasswordLinkModal = (isOpen: boolean) => {
    setResetPasswordLinkModalOpen(isOpen);
  };

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      <Button
        type="button"
        variant="contained"
        size="large"
        onClick={getResetPasswordLink}
        id="reset-password-link-button"
      >
        Reset password link
      </Button>
      <Dialog
        open={resetPasswordLinkModalOpen}
        onClose={() => handleResetPasswordLinkModal(false)}
        id="reset-password-link-dialog"
      >
        <DialogTitle id="reset-password-link-dialog-title">
          Reset password link
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            id="reset-password-link-dialog-description"
          >
            {link}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "0.5rem" }}>
          <CopyToClipboardButton id="copy-to-clipboard-button" value={link} />
          <Button
            type="button"
            variant="contained"
            color="error"
            sx={{ mx: "0.5rem" }}
            onClick={() => handleResetPasswordLinkModal(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResetPasswordLink;

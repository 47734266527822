export class ThirdPartyAuthorization {
  static checkRequiredParamsExist = (params: URLSearchParams): boolean => {
    const requiredFields = [
      "app_name",
      "username",
      "key",
      "redirect_uri",
      "client_id",
    ];

    for (const field of requiredFields) {
      if (!params.has(field)) {
        return false;
      }
    }

    return true;
  };

  static extractParams = (params: URLSearchParams) => {
    const clientApplicationName = params.get("app_name") || "";
    const username = params.get("username") || "";
    const key = params.get("key") || "";
    const redirectUri = params.get("redirect_uri") || "";
    params.delete("username");
    params.delete("app_name");
    params.delete("key");
    const otherParams = params.toString();
    return { clientApplicationName, username, redirectUri, key, otherParams };
  };
}

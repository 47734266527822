import React, { useState } from "react";

import { Tooltip } from "@mui/material";

import IconButton from "../../bp-ui/components/IconButton";
import CopyToClipboardIcon from "../../bp-ui/icons/CopyToClipboardIcon";

const message = "Copy To Clipboard";

interface OwnProps {
  id?: string;
  value: string;
}

const CopyToClipboardButton = ({ id, value }: OwnProps) => {
  const [tooltipText, setTooltipText] = useState(message);

  const handleClick = (text: string) => {
    setTooltipText("Copied To Clipboard");
    navigator.clipboard.writeText(text);
  };

  const handleLeave = () => setTooltipText(message);

  return (
    <Tooltip title={tooltipText} onMouseLeave={handleLeave}>
      <IconButton
        sx={{ marginRight: "0.05rem" }}
        id={id}
        onClick={() => handleClick(value)}
        color="primary"
      >
        <CopyToClipboardIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;

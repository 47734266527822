import React, { ReactNode } from "react";

import BPColors from "../../bp-ui/Colors";
import Alert from "../../bp-ui/components/Alert";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  message: string | ReactNode;
  customSx?: CustomSx;
}

const ErrorAlert = ({ message, customSx }: OwnProps) => {
  return (
    <Alert sx={{ backgroundColor: BPColors.AlertErrorBackground, ...customSx }}>
      {message}
    </Alert>
  );
};

export default ErrorAlert;

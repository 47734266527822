import React from "react";

import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Spinner from "../../bp-ui/icons/Spinner";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  customSx?: CustomSx;
}

const LoadingContent = ({ customSx }: OwnProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: BPColors.Black,
        ...customSx,
      }}
    >
      <Spinner colorCode="#000" />
    </Box>
  );
};

export default LoadingContent;

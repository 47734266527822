import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Pages from "../../util/pages";
import { getSharedQueryParamsAndCurrentPathAsNext } from "../util/url";

const Unauthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(Pages.home + getSharedQueryParamsAndCurrentPathAsNext(location));
  });

  return <></>;
};

export default Unauthorized;

import React from "react";

import { Link, useLocation } from "react-router-dom";

import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import Pages from "../../util/pages";
import { getSharedQueryParamsString } from "../util/url";

const SignInText = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        marginTop: "1rem",
      }}
    >
      <Typography>
        Already have an account?{" "}
        <Link
          to={Pages.home + getSharedQueryParamsString(location, true)}
          style={{ color: "inherit" }}
        >
          Log In
        </Link>
      </Typography>
    </Box>
  );
};

export default SignInText;

import React from "react";

export const BPLogo = () => {
  return (
    <svg
      width="136"
      height="58"
      viewBox="0 0 192 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_396_888)">
        <path
          d="M39.5125 35.8523C39.5125 43.7574 33.2066 50.144 25.2236 50.144C17.2406 50.144 11.0142 43.9164 11.0142 35.8523C11.0142 32.0601 12.4503 28.7077 14.7262 26.1928L5.06857 35.8523L0 30.7828L10.8949 20.005C12.3708 18.5288 13.1311 16.6103 13.1311 14.4955V0.961792H20.2743V14.4955C20.2743 18.6481 18.7984 22.1595 15.9237 25.0348L15.6032 25.3553C18.1176 23.0392 21.5513 21.6426 25.2211 21.6426C33.3233 21.6426 39.51 28.0691 39.51 35.8548M33.0451 35.8548C33.0451 31.6227 29.5319 28.1908 25.2211 28.1908C20.9103 28.1908 17.4791 31.7842 17.4791 35.8548C17.4791 39.9254 20.9103 43.5983 25.2211 43.5983C29.5319 43.5983 33.0451 39.9651 33.0451 35.8548Z"
          fill="#262626"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M62.973 21.9637C65.9694 21.9637 68.2975 22.59 69.9522 23.84C71.6069 25.0925 72.4343 26.8395 72.4343 29.081C72.4343 30.7237 71.997 32.0731 71.1249 33.1292C70.2504 34.1854 68.9956 34.8961 67.3508 35.2614C71.443 35.9647 73.4903 38.1168 73.4903 41.7127C73.4903 44.0859 72.6107 45.9497 70.8516 47.3041C69.0925 48.6585 66.5831 49.3369 63.3258 49.3369H52.9253V21.9637H62.973ZM57.4224 33.579H63.0922C64.6302 33.579 65.8228 33.2261 66.6701 32.5229C67.5173 31.8196 67.9422 30.8554 67.9422 29.6277C67.9422 28.4001 67.5173 27.4458 66.6701 26.755C65.8228 26.0641 64.6302 25.7187 63.0922 25.7187H57.4224V33.579ZM57.4224 45.5447H63.3655C65.1371 45.5447 66.4986 45.1868 67.4502 44.4686C68.4018 43.7529 68.8764 42.7539 68.8764 41.4766C68.8764 40.1993 68.3944 39.1183 67.4303 38.3876C66.4663 37.6595 65.1097 37.2942 63.3655 37.2942H57.4224V45.5447Z"
          fill="#262626"
        />
        <path
          d="M39.5125 35.8523C39.5125 43.7574 33.2066 50.144 25.2236 50.144C17.2406 50.144 11.0142 43.9164 11.0142 35.8523C11.0142 32.0601 12.4503 28.7077 14.7262 26.1928L5.06857 35.8523L0 30.7828L10.8949 20.005C12.3708 18.5288 13.1311 16.6103 13.1311 14.4955V0.961792H20.2743V14.4955C20.2743 18.6481 18.7984 22.1595 15.9237 25.0348L15.6032 25.3553C18.1176 23.0392 21.5513 21.6426 25.2211 21.6426C33.3233 21.6426 39.51 28.0691 39.51 35.8548M33.0451 35.8548C33.0451 31.6227 29.5319 28.1908 25.2211 28.1908C20.9103 28.1908 17.4791 31.7842 17.4791 35.8548C17.4791 39.9254 20.9103 43.5983 25.2211 43.5983C29.5319 43.5983 33.0451 39.9651 33.0451 35.8548Z"
          fill="#262626"
        />
        <path
          d="M75.5826 33.9492C76.4025 32.3985 77.5504 31.1982 79.0238 30.3508C80.4971 29.5034 82.1842 29.0809 84.0874 29.0809C85.9906 29.0809 87.7174 29.4711 89.1907 30.2539C90.6641 31.0367 91.8219 32.145 92.6716 33.5764C93.5189 35.0103 93.9562 36.6927 93.981 38.6212C93.981 39.143 93.9413 39.6773 93.8643 40.224H78.7728V40.4576C78.8772 42.2046 79.4238 43.5864 80.4151 44.6027C81.404 45.6191 82.7208 46.1286 84.3632 46.1286C85.6676 46.1286 86.7608 45.8229 87.6478 45.2091C88.5348 44.5978 89.1212 43.7305 89.4069 42.6097H93.6307C93.2655 44.6425 92.2816 46.3125 90.679 47.6147C89.0764 48.9193 87.0763 49.5704 84.6787 49.5704C82.5941 49.5704 80.7754 49.148 79.225 48.3006C77.6746 47.4531 76.4746 46.2603 75.6273 44.722C74.7801 43.1838 74.3552 41.397 74.3552 39.3642C74.3552 37.3314 74.7652 35.4999 75.5876 33.9492M89.6827 37.1376C89.4988 35.6788 88.9199 34.5382 87.9435 33.7156C86.967 32.8955 85.7347 32.4855 84.2489 32.4855C82.8674 32.4855 81.6748 32.9104 80.6711 33.7554C79.6673 34.6028 79.0884 35.731 78.9318 37.1376H89.6827Z"
          fill="#262626"
        />
        <path
          d="M114.434 45.6609V49.3363H112.206C110.901 49.3363 109.965 49.0629 109.391 48.5162C108.817 47.9695 108.531 47.1743 108.531 46.1305C107.02 48.4243 104.817 49.5724 101.925 49.5724C99.6834 49.5724 97.8846 49.0505 96.528 48.0093C95.1714 46.968 94.4956 45.5316 94.4956 43.7076C94.4956 41.6475 95.2261 40.0645 96.6845 38.9561C98.143 37.8478 100.267 37.2936 103.058 37.2936H108.101V36.0809C108.101 34.9601 107.711 34.0804 106.928 33.4417C106.146 32.8031 105.065 32.485 103.684 32.485C102.459 32.485 101.447 32.7534 100.655 33.2852C99.8598 33.8195 99.3828 34.5302 99.2287 35.4174H95.0845C95.2932 33.4094 96.1802 31.8537 97.743 30.7454C99.3058 29.6371 101.353 29.0829 103.882 29.0829C106.566 29.0829 108.638 29.7091 110.099 30.9591C111.557 32.2116 112.288 34.0083 112.288 36.3567V44.2568C112.288 45.1962 112.73 45.6633 113.617 45.6633H114.437L114.434 45.6609ZM108.101 40.4198H102.861C101.557 40.4198 100.556 40.6733 99.8499 41.1827C99.1468 41.6922 98.7939 42.4402 98.7939 43.4317C98.7939 44.2916 99.1269 44.9824 99.7903 45.5043C100.454 46.0262 101.361 46.2871 102.508 46.2871C104.255 46.2871 105.617 45.8174 106.593 44.8805C107.57 43.9412 108.071 42.6912 108.099 41.1256V40.4223L108.101 40.4198Z"
          fill="#262626"
        />
        <path
          d="M116.093 33.0292H112.615V29.314H116.093V23.7225H120.354V29.314H125.164V33.0292H120.354V43.4715C120.354 44.2543 120.511 44.8084 120.824 45.134C121.137 45.4595 121.671 45.6235 122.426 45.6235H125.631V49.3387H121.564C119.661 49.3387 118.274 48.8964 117.4 48.0092C116.528 47.1221 116.09 45.7553 116.09 43.9039V33.0316L116.093 33.0292Z"
          fill="#262626"
        />
        <path
          d="M142.598 30.1919C144.032 31.012 145.152 32.1999 145.96 33.7505C146.767 35.3012 147.172 37.1203 147.172 39.2053C147.172 41.2903 146.767 43.0323 145.96 44.583C145.152 46.1337 144.029 47.334 142.598 48.1814C141.165 49.0288 139.535 49.4513 137.711 49.4513C136.225 49.4513 134.936 49.1779 133.84 48.6312C132.744 48.0845 131.872 47.3415 131.221 46.4021V57.0382H127V29.1954H130.675L131.184 32.1675C132.799 30.0304 134.976 28.9618 137.714 28.9618C139.537 28.9618 141.167 29.3718 142.601 30.1944M141.252 44.0189C142.332 42.7664 142.874 41.1635 142.874 39.2103C142.874 37.257 142.332 35.6268 141.252 34.3991C140.168 33.174 138.755 32.5602 137.008 32.5602C135.261 32.5602 133.855 33.1665 132.787 34.3793C131.718 35.592 131.184 37.175 131.184 39.1307C131.184 41.0865 131.718 42.7664 132.787 44.0189C133.855 45.2714 135.261 45.8951 137.008 45.8951C138.755 45.8951 140.168 45.2689 141.252 44.0189Z"
          fill="#262626"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M153.084 48.2991C151.534 47.4517 150.334 46.2539 149.486 44.7007C148.639 43.15 148.217 41.3583 148.217 39.323C148.217 37.2877 148.639 35.5358 149.486 33.9851C150.334 32.4344 151.531 31.2266 153.084 30.3668C154.634 29.5069 156.413 29.077 158.421 29.077C160.429 29.077 162.208 29.5069 163.758 30.3668C165.308 31.2266 166.508 32.4319 167.356 33.9851C168.203 35.5358 168.625 37.3151 168.625 39.323C168.625 41.331 168.2 43.15 167.356 44.7007C166.508 46.2514 165.308 47.4517 163.758 48.2991C162.208 49.1465 160.429 49.569 158.421 49.569C156.413 49.569 154.634 49.1465 153.084 48.2991ZM162.702 44.1142C163.783 42.8767 164.324 41.2788 164.324 39.323C164.324 37.3673 163.783 35.7718 162.702 34.5318C161.621 33.2942 160.193 32.6729 158.421 32.6729C156.649 32.6729 155.228 33.2917 154.16 34.5318C153.092 35.7693 152.557 37.3673 152.557 39.323C152.557 41.2788 153.092 42.8767 154.16 44.1142C155.228 45.3518 156.649 45.9706 158.421 45.9706C160.193 45.9706 161.621 45.3518 162.702 44.1142Z"
          fill="#262626"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M181.133 29.3149V33.2637H179.257C177.51 33.2637 176.245 33.8303 175.465 34.9635C174.682 36.0967 174.292 37.538 174.292 39.285V49.3347H170.067V29.3124H173.822L174.292 32.3243C174.866 31.3849 175.609 30.6494 176.521 30.1151C177.433 29.5808 178.658 29.3124 180.197 29.3124H181.136L181.133 29.3149Z"
          fill="#262626"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M182.497 23.7225H186.722V29.314H191.57V33.0292H186.722V43.4715C186.722 44.2543 186.878 44.8084 187.192 45.134C187.505 45.462 188.039 45.6235 188.794 45.6235H192V49.3387H187.935C186.031 49.3387 184.649 48.8964 183.79 48.0092C182.93 47.1221 182.5 45.7553 182.5 43.9039V23.725L182.497 23.7225Z"
          fill="#262626"
        />
      </g>
      <defs>
        <clipPath id="clip0_396_888">
          <rect width="192" height="58" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
};

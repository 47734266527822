import React from "react";

import PasswordCreation from "../../components/registration/PasswordCreation";
import RegistrationLayout from "../../components/registration/RegistrationLayout";

const PasswordCreationPage = () => {
  return (
    <RegistrationLayout>
      <PasswordCreation />
    </RegistrationLayout>
  );
};

export default PasswordCreationPage;

export const basicUsernameValidation = (username: string): boolean => {
  const unicodeUsernameRegex = new RegExp(/^[\w.@+-]+$/);
  return unicodeUsernameRegex.test(username);
};

export const basicEmailValidation = (email: string): boolean => {
  const emailRegex =
    /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const basicPasswordValidation = (password: string): boolean => {
  return password.length >= 8;
};

export const containsFourByteCharacters = (value: string) => {
  // This regex matches characters outside the Basic Multilingual Plane
  const fourByteCharRegex = /[\u{10000}-\u{10FFFF}]/gu;

  return fourByteCharRegex.test(value);
};

export const basicNameValidation = (value: string) => {
  return !(value.trim().length === 0 || containsFourByteCharacters(value));
};

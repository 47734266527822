import React, { useEffect, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { verifyToken } from "../api/auth";
import EmailVerifiedContent from "../components/emailVerification/EmailVerifiedContent";
import InvalidVerificationLink from "../components/shared/InvalidVerificationLink";
import LoadingContent from "../components/shared/LoadingContent";
import { getSharedQueryParamsString } from "../components/util/url";
import Pages from "../util/pages";

const EmailVerifiedPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const token = params.get("token") || "";
  const [isLoading, setIsLoading] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [email, setEmail] = useState("");

  const verifyEmailMutation = useMutation({
    mutationFn: async () => {
      return verifyToken(token);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setValidToken(true);
      setEmail(data.email);
    },
    onError: () => {
      setIsLoading(false);
      setValidToken(false);
    },
  });

  useEffect(() => {
    verifyEmailMutation.mutate();
  }, []);

  const navigateToSettings = () => {
    navigate(Pages.settings + getSharedQueryParamsString(location, true));
  };

  return (
    <>
      {isLoading ? (
        <LoadingContent />
      ) : validToken ? (
        <EmailVerifiedContent onClick={navigateToSettings} email={email} />
      ) : (
        <InvalidVerificationLink onResendNewLinkClick={navigateToSettings} />
      )}
    </>
  );
};

export default EmailVerifiedPage;

import React from "react";

import ProfileEdit from "../../components/settings/ProfileEdit";
import FullPageLayout from "../../components/shared/FullPageLayout";

const ProfileEditPage = () => {
  return (
    <FullPageLayout>
      <ProfileEdit />
    </FullPageLayout>
  );
};

export default ProfileEditPage;

import React, { useState } from "react";

import { handleMouseDownEvent } from "./util";
import Box from "../../bp-ui/components/Box";
import FilledInput from "../../bp-ui/components/FilledInput";
import FormControl from "../../bp-ui/components/FormControl";
import FormHelperText from "../../bp-ui/components/FormHelperText";
import IconButton from "../../bp-ui/components/IconButton";
import InputAdornment from "../../bp-ui/components/InputAdornment";
import InputLabel from "../../bp-ui/components/InputLabel";
import { VisibilityIcon } from "../../bp-ui/icons/VisibilityIcon";
import { VisibilityOffIcon } from "../../bp-ui/icons/VisibilityOffIcon";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  id: string;
  customSx?: CustomSx;
  onChange: (value: string) => void;
  value: string;
  label: string;
  error?: boolean;
  helperText?: string;
}

export const PasswordField = ({
  value,
  onChange,
  label,
  error,
  helperText,
  customSx,
  id,
}: OwnProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ ...customSx }}>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <FilledInput
          id={id}
          error={error}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownEvent}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          aria-describedby={`${id}-helper-text`}
          inputProps={{ maxLength: 256 }}
        />
      </FormControl>
      {helperText && (
        <FormHelperText id={`${id}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

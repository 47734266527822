import * as Sentry from "@sentry/react";

export const initalizeSentry = () => {
  Sentry.init({
    dsn: window.appConfig.sentryDsnUrl,
    environment: window.appConfig.environment,
    release: window.appConfig.sentryRelease,
    sendDefaultPii: false,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: window.appConfig.sentryTracesSampleRate,
    replaysOnErrorSampleRate: window.appConfig.sentryReplaysOnErrorSampleRate,
    replaysSessionSampleRate: window.appConfig.sentryReplaysSessionSampleRate,
  });

  Sentry.getCurrentScope().setLevel("error");

  window.appConfig.enableSentrySessionReplay &&
    Sentry.addIntegration(
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: false,
      })
    );
};

import { QueryClient } from "@tanstack/react-query";

import { getCurrentUserQuery } from "../../queries/useCurrentUser";

export const userLoader = (queryClient: QueryClient) => async () => {
  const query = getCurrentUserQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

import React from "react";

import RegistrationLayout from "../../components/registration/RegistrationLayout";
import SignUp from "../../components/registration/SignUp";

const SignUpPage = () => {
  return (
    <RegistrationLayout>
      <SignUp />
    </RegistrationLayout>
  );
};

export default SignUpPage;

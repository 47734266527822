import React from "react";

import { handleMouseDownEvent } from "./util";
import Box from "../../bp-ui/components/Box";
import FilledInput from "../../bp-ui/components/FilledInput";
import FormControl from "../../bp-ui/components/FormControl";
import FormHelperText from "../../bp-ui/components/FormHelperText";
import IconButton from "../../bp-ui/components/IconButton";
import InputAdornment from "../../bp-ui/components/InputAdornment";
import InputLabel from "../../bp-ui/components/InputLabel";
import ErrorIcon from "../../bp-ui/icons/ErrorIcon";
import { HighlightOffIcon } from "../../bp-ui/icons/HighlightOffIcon";

interface OwnProps {
  value: string;
  error?: boolean;
  helperText?: string;
  onChange: (value: string) => void;
}

const UsernameField = ({ value, onChange, error, helperText }: OwnProps) => {
  const handleClickClearUsername = () => onChange("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const selectAdornmentIcon = () => {
    if (error) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear username field"
            onMouseDown={handleMouseDownEvent}
            edge="end"
          >
            <ErrorIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    if (value.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear username field"
            onClick={handleClickClearUsername}
            onMouseDown={handleMouseDownEvent}
            edge="end"
          >
            <HighlightOffIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    return undefined;
  };

  return (
    <Box>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor="username">Username</InputLabel>
        <FilledInput
          error={error}
          id="username"
          value={value}
          onChange={handleChange}
          endAdornment={selectAdornmentIcon()}
          aria-describedby="username-helper-text"
          inputProps={{ maxLength: 150 }}
        />
      </FormControl>
      {error && helperText && (
        <FormHelperText id="username-helper-text" error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default UsernameField;

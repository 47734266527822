import SxProps from "../../bp-ui/SxProps";
import Theme from "../../bp-ui/Theme";

export const mobileScreenSx = (customSx: SxProps<Theme>) => {
  return {
    "@media (max-width:480px)": {
      ...customSx,
    },
  };
};

export const isMobileScreen = () => {
  return typeof window !== "undefined" && window.innerWidth <= 480;
};

import React from "react";

import PasswordEdit from "../../components/settings/PasswordEdit";
import FullPageLayout from "../../components/shared/FullPageLayout";

const PasswordEditPage = () => {
  return (
    <FullPageLayout>
      <PasswordEdit />
    </FullPageLayout>
  );
};

export default PasswordEditPage;

import { useEffect } from "react";

import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Location, NavigateFunction } from "react-router-dom";

import Pages from "./pages";
import { is401ClientError } from "../api/httpClient";
import { getSharedQueryParamsString } from "../components/util/url";
import {
  currentUserQueryKey,
  useCurrentUserQuery,
} from "../queries/useCurrentUser";

function useAuthCurrentUserQuery(
  navigate: NavigateFunction,
  location: Location,
  queryClient: QueryClient
) {
  const { data: currentUser = null, isError, error } = useCurrentUserQuery();

  useEffect(() => {
    if (
      currentUser == null &&
      isError &&
      error instanceof AxiosError &&
      is401ClientError(error)
    ) {
      queryClient.removeQueries({ queryKey: [currentUserQueryKey] });
      navigate(Pages.home + getSharedQueryParamsString(location, true));
    }
  }, [isError]);

  return currentUser;
}

export default useAuthCurrentUserQuery;

import React from "react";

const ArrowForwardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.53061 3.96932L17.0306 11.4693C17.1003 11.539 17.1557 11.6217 17.1934 11.7127C17.2312 11.8038 17.2506 11.9014 17.2506 11.9999C17.2506 12.0985 17.2312 12.1961 17.1934 12.2872C17.1557 12.3782 17.1003 12.4609 17.0306 12.5306L9.53062 20.0306C9.38988 20.1713 9.19901 20.2504 8.99999 20.2504C8.80097 20.2504 8.61009 20.1713 8.46936 20.0306C8.32863 19.8898 8.24957 19.699 8.24957 19.4999C8.24957 19.3009 8.32863 19.1101 8.46936 18.9693L15.4397 11.9999L8.46936 5.03057C8.39968 4.96089 8.34441 4.87817 8.30669 4.78712C8.26898 4.69608 8.24957 4.59849 8.24957 4.49995C8.24957 4.4014 8.26898 4.30382 8.30669 4.21278C8.34441 4.12173 8.39968 4.03901 8.46936 3.96932C8.53905 3.89964 8.62177 3.84437 8.71282 3.80665C8.80386 3.76894 8.90144 3.74953 8.99999 3.74953C9.09854 3.74953 9.19612 3.76894 9.28716 3.80665C9.37821 3.84437 9.46093 3.89964 9.53061 3.96932Z"
        fill="#141414"
      />
    </svg>
  );
};

export default ArrowForwardIcon;

import React from "react";

const ArrowBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.4694 20.0306L6.96945 12.5306C6.89971 12.4609 6.84439 12.3782 6.80665 12.2871C6.76891 12.1961 6.74948 12.0985 6.74948 11.9999C6.74948 11.9014 6.76891 11.8038 6.80665 11.7127C6.84439 11.6217 6.89971 11.539 6.96945 11.4693L14.4694 3.9693C14.6102 3.82857 14.801 3.74951 15.0001 3.74951C15.1991 3.74951 15.39 3.82857 15.5307 3.9693C15.6714 4.11003 15.7505 4.30091 15.7505 4.49993C15.7505 4.69895 15.6714 4.88982 15.5307 5.03055L8.56038 11.9999L15.5307 18.9693C15.6004 19.039 15.6557 19.1217 15.6934 19.2128C15.7311 19.3038 15.7505 19.4014 15.7505 19.4999C15.7505 19.5985 15.7311 19.6961 15.6934 19.7871C15.6557 19.8781 15.6004 19.9609 15.5307 20.0306C15.461 20.1002 15.3783 20.1555 15.2872 20.1932C15.1962 20.2309 15.0986 20.2503 15.0001 20.2503C14.9015 20.2503 14.8039 20.2309 14.7129 20.1932C14.6219 20.1555 14.5391 20.1002 14.4694 20.0306Z"
        fill="#696B70"
      />
    </svg>
  );
};

export default ArrowBackIcon;

import React from "react";

import Box from "../../bp-ui/components/Box";
import FilledInput from "../../bp-ui/components/FilledInput";
import FormControl from "../../bp-ui/components/FormControl";
import FormHelperText from "../../bp-ui/components/FormHelperText";
import IconButton from "../../bp-ui/components/IconButton";
import InputAdornment from "../../bp-ui/components/InputAdornment";
import InputLabel from "../../bp-ui/components/InputLabel";
import { HighlightOffIcon } from "../../bp-ui/icons/HighlightOffIcon";
import { CustomSx } from "../../models/custom-sx";
import InputProps from "../../models/input-props";
import { handleMouseDownEvent } from "../account/util";

interface OwnProps {
  id: string;
  customSx?: CustomSx;
  onChange: (value: string) => void;
  value: string;
  label: string;
  error?: boolean;
  helperText?: string;
  inputProps: InputProps;
}

const TextField = ({
  value,
  onChange,
  label,
  id,
  customSx,
  error,
  helperText,
  inputProps,
}: OwnProps) => {
  const handleClickClear = () => onChange("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={customSx}>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <FilledInput
          id={id}
          error={error}
          value={value}
          onChange={handleChange}
          endAdornment={
            value.length > 0 && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear username field"
                  onClick={handleClickClear}
                  onMouseDown={handleMouseDownEvent}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            )
          }
          aria-describedby={`${id}-helper-text`}
          inputProps={inputProps}
        />
      </FormControl>
      {helperText && (
        <FormHelperText id={`${id}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TextField;

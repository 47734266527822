import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import GoBack from "./GoBack";
import { is401ClientError, is4xxClientError } from "../../api/httpClient";
import { updatePassword } from "../../api/settings";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { currentUserQueryKey } from "../../queries/useCurrentUser";
import { PASSWORD_DESCRIPTION } from "../../util/errorMessages";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import { PASSWORD_UPDATED_MSG } from "../../util/successMessages";
import useAuthCurrentUserQuery from "../../util/useAuthCurrentUserQuery";
import { PasswordField } from "../account/PasswordField";
import Generic4xxErrorAlert from "../shared/Generic4xxErrorAlert";
import PrimaryButton from "../shared/PrimaryButton";
import SuccessAlert from "../shared/SuccessAlert";
import TextAction from "../shared/TextAction";
import { basicPasswordValidation } from "../util/account-fields-validation";
import { mobileScreenSx } from "../util/screen";
import { getSharedQueryParamsString } from "../util/url";

const PasswordEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  useAuthCurrentUserQuery(navigate, location, queryClient);

  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showGenericErrorAlert, setShowGenericErrorAlert] = useState(false);

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    if (value.length === 0) {
      setError(true);
      return;
    }

    setError(false);
  };

  const updatePasswordMutation = useMutation({
    mutationFn: async () => {
      await updatePassword(password);
    },
    onSuccess: () => {
      setError(false);
      setShowAlert(true);
      setTimeout(() => {
        queryClient.removeQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home + getSharedQueryParamsString(location, true));
      }, 1000);
    },
    onError: (error: AxiosError) => {
      if (is401ClientError(error)) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home);
      }
      if (is4xxClientError(error)) {
        setShowGenericErrorAlert(true);
      }
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    const isValid = basicPasswordValidation(password);

    if (!isValid) {
      setError(true);
      return;
    }

    setShowGenericErrorAlert(false);
    updatePasswordMutation.mutate();
  };

  return (
    <Box
      sx={{
        width: "24rem",
        color: BPColors.PrimaryMain,
        ...mobileScreenSx({
          width: "100%",
          paddingTop: "2rem",
        }),
      }}
    >
      <GoBack onClick={() => navigateTo(Pages.settings)} />
      <Typography
        component="h1"
        variant="h3"
        sx={{
          marginY: "1rem",
        }}
      >
        Edit password
      </Typography>
      <Typography>
        Updating your current password will log you out of all devices.
      </Typography>
      <Box
        id="edit-password-form"
        component="form"
        sx={{ marginY: "2rem" }}
        onSubmit={onSubmit}
      >
        {showAlert && (
          <SuccessAlert
            message={PASSWORD_UPDATED_MSG}
            customSx={{ marginBottom: "1.5rem" }}
          />
        )}
        {showGenericErrorAlert && (
          <Generic4xxErrorAlert
            customSx={{ marginTop: "1.625rem", marginBottom: "1.5rem" }}
          />
        )}
        <PasswordField
          id="new-password"
          label="Password"
          helperText={PASSWORD_DESCRIPTION}
          value={password}
          error={error}
          onChange={onPasswordChange}
          customSx={{
            marginBottom: "1.5rem",
          }}
        />
        <PrimaryButton
          type="submit"
          label="Save"
          isDisabled={password.length === 0}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <TextAction text="Cancel" onClick={() => navigateTo(Pages.settings)} />
      </Box>
    </Box>
  );
};

export default PasswordEdit;

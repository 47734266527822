import type { Location } from "react-router-dom";
const sharedQueryParams: string[] = [
  "next",
  "client_id",
  "response_type",
  "code_challenge",
  "code_challenge_method",
  "redirect_uri",
];

export const getNext = (currentLocation: Location) => {
  const queryParams = new URLSearchParams(currentLocation.search);

  return queryParams.get("next");
};

export const getAuthorizeEndpointUrl = (
  currentLocation: Location
): null | string => {
  const queryParams = new URLSearchParams(currentLocation.search);

  if (queryParams.has("next")) {
    return queryParams.get("next");
  }

  if (
    queryParams.has("client_id") &&
    queryParams.has("redirect_uri") &&
    queryParams.has("response_type")
  ) {
    return `/o/authorize/?${queryParams.toString()}`;
  }

  return null;
};

export const navigateToLocation = (next: string) => {
  window.location.href = next;
};

export const isAuthorizeUrl = (url: string) => {
  return url.includes("/o/authorize/");
};

export const getSharedQueryParamsString = (
  currentLocation: Location,
  appendParamsSign = false
) => {
  const searchParams = new URLSearchParams(currentLocation.search);
  const sharedParams = new URLSearchParams();

  Array.from(searchParams.entries())
    .filter(([param]) => sharedQueryParams.includes(param))
    .forEach(([param, value]) => {
      sharedParams.append(param, value);
    });

  if (appendParamsSign && Array.from(sharedParams).length > 0) {
    return "?" + sharedParams.toString();
  }

  return sharedParams.toString();
};

export const getSharedQueryParamsAndCurrentPathAsNext = (
  currentLocation: Location
) => {
  const searchParams = new URLSearchParams(currentLocation.search);

  searchParams.append("next", currentLocation.pathname);

  return "?" + searchParams.toString();
};

export const getQueryParamValue = (
  search: string,
  param: string
): string | null => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
};

import React from "react";

import { Typography } from "@mui/material";

import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import ArrowBackIcon from "../../bp-ui/icons/ArrowBackIcon";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  onClick: () => void;
  customSx?: CustomSx;
}

const GoBack = ({ onClick, customSx }: OwnProps) => {
  return (
    <Box sx={customSx}>
      <Box
        onClick={onClick}
        sx={{ display: "flex", cursor: "pointer", maxWidth: "6rem" }}
      >
        <ArrowBackIcon />
        <Typography color={BPColors.PrimaryLight}>Go Back</Typography>
      </Box>
    </Box>
  );
};

export default GoBack;

import React from "react";

import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  onClick: () => void;
  label: string;
  boxSx?: CustomSx;
  typographySx?: CustomSx;
}

const SecondaryButtonLink = ({
  onClick,
  label,
  boxSx,
  typographySx,
}: OwnProps) => {
  return (
    <Box
      sx={{
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        ...boxSx,
      }}
    >
      <Link onClick={onClick}>
        <Typography
          sx={{
            textAlign: "center",
            ...typographySx,
          }}
        >
          {label}
        </Typography>
      </Link>
    </Box>
  );
};

export default SecondaryButtonLink;

import React from "react";

import MuiFormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText";

const FormHelperText = (props: FormHelperTextProps) => (
  <MuiFormHelperText {...props} />
);

export default FormHelperText;

import { devApiSpec } from "../docs/openApiSpecification";

export const prodApiSpec = {
  openapi: "3.0.3",
  info: {
    description:
      "This is the external Identity service documentation.\n\nThe process below describes how to integrate with our Identity Service via an Authorization Code grant flow to retrieve an access token:\n\n1. The login action should make a call to the /o/authorize route with your client ID, grant type, and redirect URI\n\n2. The user will enter our Identity Service UI where they can log in or create an account.\n\n3. Once successfully authorized a code will be returned to the redirect URI. In some cases, the user will be asked to authorize the client to gain access their Beatport resources, once they accept a code will be sent to the redirect URI.\n\n4. Use the code in the /o/token route to retrieve an access token. This route requires a client ID, client secret, grant type, code, and redirect URI.\n\n5. We will return an access token as well as a refresh token, use the access token to make calls to our API.\n\n6. To refresh the token, call the /o/token route with grant_type=refresh_token",
    version: "1.0.0",
    title: "Identity Service",
    termsOfService: {},
    contact: {},
    license: {
      name: {},
      url: {},
    },
  },
  schemes: devApiSpec.schemes,
  tags: [
    {
      name: "OAuth",
    },
  ],
  paths: {
    "/o/authorize/": devApiSpec.paths["/o/authorize/"],
    "/o/token/": devApiSpec.paths["/o/token/"],
    "/o/introspect/": devApiSpec.paths["/o/introspect/"],
    "/o/userinfo/": devApiSpec.paths["/o/userinfo/"],
  },
  components: {
    schemas: {
      TokenBody: devApiSpec.components.schemas.TokenBody,
      IntrospectBody: devApiSpec.components.schemas.IntrospectBody,
      UserInfoBody: devApiSpec.components.schemas.UserInfoBody,
    },
    securitySchemes: devApiSpec.components.securitySchemes,
  },
  externalDocs: {
    description: "More information on our Identity Service can be found here",
    url: "https://partnerportal.beatport.com/hc/en-us/articles/24126185176468-Authenticating-with-our-Identity-Service",
  },
};

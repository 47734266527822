import React from "react";

import { BPLogo } from "../../bp-ui/branding/BPLogo";
import Box from "../../bp-ui/components/Box";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  customSx?: CustomSx;
}

export const BrandLogo = ({ customSx }: OwnProps) => {
  return (
    <Box
      sx={{
        marginBottom: "2rem",
        ...customSx,
      }}
    >
      <BPLogo />
    </Box>
  );
};

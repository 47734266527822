import React from "react";

import ErrorAlert from "./ErrorAlert";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  customSx?: CustomSx;
}

const LimitErrorAlert = ({ customSx }: OwnProps) => {
  return (
    <ErrorAlert
      customSx={{ ...customSx }}
      message="Your click per minute is too fast, please slow down."
    />
  );
};

export default LimitErrorAlert;

import { QueryClient } from "@tanstack/react-query";
import { Location, NavigateFunction, NavigateOptions } from "react-router-dom";

import { getSharedQueryParamsString } from "../components/util/url";
import { currentUserQueryKey } from "../queries/useCurrentUser";

export const invalidateQueriesAndNavigateTo = (
  path: string,
  queryClient: QueryClient,
  navigate: NavigateFunction,
  location: Location,
  options?: NavigateOptions
) => {
  queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
  navigate(path + getSharedQueryParamsString(location, true), options);
};

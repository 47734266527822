import React from "react";

import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import ArrowForwardIcon from "../../bp-ui/icons/ArrowForwardIcon";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  label?: string;
  text: string;
  icon?: React.ReactNode;
  showIcon?: boolean;
  onEditClicked?: () => void;
  customSx?: CustomSx;
}

const AccountSettingsField = ({
  text,
  label,
  customSx,
  showIcon,
  icon,
  onEditClicked,
}: OwnProps) => {
  return (
    <Box
      sx={{
        paddingX: "1rem",
        paddingY: "0.75rem",
        border: "0.063rem solid #E8EBF1",
        borderRadius: "0.25rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "3.75rem",
        cursor: onEditClicked ? "pointer" : "default",
        "&:hover": {
          bgcolor: BPColors.SecondaryMain,
        },
        ...customSx,
      }}
      onClick={onEditClicked ? onEditClicked : undefined}
    >
      {showIcon && icon && <Box id="account-field-icon">{icon}</Box>}
      <Box
        id="account-field-info-container"
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "80%",
        }}
      >
        {label && (
          <Box id="account-field-label">
            <Typography
              sx={{
                color: "#696B70",
              }}
              variant="body2"
            >
              {label}
            </Typography>
          </Box>
        )}
        <Box id="account-field-text">
          <Typography
            sx={{
              color: onEditClicked ? "inherit" : "#696B70",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="body1"
            className="sentry-mask"
          >
            {text}
          </Typography>
        </Box>
      </Box>
      {onEditClicked && (
        <Box id="account-field-edit-icon" sx={{ marginY: "auto" }}>
          <ArrowForwardIcon />
        </Box>
      )}
    </Box>
  );
};

export default AccountSettingsField;

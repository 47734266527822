import React, { useCallback, useEffect, useState } from "react";

import { getAppConfig } from "../../api/appConfig";
import { DEFAULT_APP_CONFIG } from "../../util/appConfig";
import { isDevelopmentEnvironment } from "../../util/environment";
import { initalizeSentry } from "../../util/sentryInitialization";

interface OwnProps {
  children: React.ReactNode;
}

const AppConfig = ({ children }: OwnProps) => {
  const [count, setCount] = useState(0);

  const incrementCount = useCallback(() => {
    setCount((c) => c + 1);
  }, [setCount]);

  useEffect(() => {
    const loadAppConfig = async () => {
      if (isDevelopmentEnvironment() && !window.appConfig) {
        window.appConfig = DEFAULT_APP_CONFIG;
        incrementCount();
      }

      if (!isDevelopmentEnvironment() && !window.appConfig) {
        try {
          const data = await getAppConfig();
          window.appConfig = data;
          incrementCount();
        } catch (error) {
          console.error(error);
        }
      }
    };
    loadAppConfig();
  }, [count, incrementCount]);

  if (!window.appConfig) {
    return <></>;
  }

  if (window.appConfig.enableSentry) {
    initalizeSentry();
  }

  return <>{children}</>;
};

export default AppConfig;

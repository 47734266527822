import React from "react";

import {
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogProps,
  DialogTitleProps,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";

export const Dialog = (props: DialogProps) => <MuiDialog {...props} />;

export const DialogTitle = (props: DialogTitleProps) => (
  <MuiDialogTitle {...props} />
);

export const DialogContent = (props: DialogContentProps) => (
  <MuiDialogContent {...props} />
);

export const DialogContentText = (props: DialogContentTextProps) => (
  <MuiDialogContentText {...props} />
);

export const DialogActions = (props: DialogActionsProps) => (
  <MuiDialogActions {...props} />
);

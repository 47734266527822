import { produce } from "immer";
import { create } from "zustand";

import { FormField } from "../models/field";

interface SignUpFormData {
  username: FormField<string>;
  email: FormField<string>;
}

interface PasswordFormData {
  password: FormField<string>;
}

interface ProfileFormData {
  firstName: FormField<string>;
  lastName: FormField<string>;
}

interface AccountFormData {
  turnstileToken: FormField<string>;
}

interface RegistrationFormState {
  step: number;
  signUpData: SignUpFormData;
  passwordData: PasswordFormData;
  profileData: ProfileFormData;
  accountData: AccountFormData;
}

interface RegistrationFormActions {
  incrementStep: () => void;
  decrementStep: () => void;
  setUsername: (value: string, error: boolean, errorMessage: string) => void;
  setEmail: (value: string, error: boolean, errorMessage: string) => void;
  setPassword: (value: string, error: boolean, errorMessage: string) => void;
  setFirstName: (value: string, error: boolean, errorMessage: string) => void;
  setLastName: (value: string, error: boolean, errorMessage: string) => void;
  setTurnstileToken: (
    value: string,
    error: boolean,
    errorMessage: string
  ) => void;
}

const initialRegistrationFormState: RegistrationFormState = {
  step: 0,
  signUpData: {
    username: { value: "", error: false, errorMessage: "" },
    email: { value: "", error: false, errorMessage: "" },
  },
  passwordData: {
    password: { value: "", error: false, errorMessage: "" },
  },
  profileData: {
    firstName: { value: "", error: false, errorMessage: "" },
    lastName: { value: "", error: false, errorMessage: "" },
  },
  accountData: {
    turnstileToken: { value: "", error: false, errorMessage: "" },
  },
};

export const useRegistrationStore = create<
  RegistrationFormState & RegistrationFormActions
>()((set) => {
  const setFormField =
    (fieldGetter: (state: RegistrationFormState) => FormField<string>) =>
    (value: string, error: boolean, errorMessage: string) => {
      set(
        produce((state: RegistrationFormState) => {
          const field = fieldGetter(state);
          field.value = value;
          field.error = error;
          field.errorMessage = errorMessage;
        })
      );
    };

  const increment = () => () => {
    set(
      produce((state: RegistrationFormState) => {
        if (state.step < 3) {
          state.step = state.step + 1;
        }
      })
    );
  };

  const decrement = () => () => {
    set(
      produce((state: RegistrationFormState) => {
        if (state.step > 0) {
          state.step = state.step - 1;
        }
      })
    );
  };

  return {
    ...initialRegistrationFormState,
    incrementStep: increment(),
    decrementStep: decrement(),
    setUsername: setFormField((state) => state.signUpData.username),
    setEmail: setFormField((state) => state.signUpData.email),
    setPassword: setFormField((state) => state.passwordData.password),
    setFirstName: setFormField((state) => state.profileData.firstName),
    setLastName: setFormField((state) => state.profileData.lastName),
    setTurnstileToken: setFormField(
      (state) => state.accountData.turnstileToken
    ),
  };
});

import React from "react";

import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";

interface OwnProps {
  label: string;
  value: string;
}

const MetadataRow = ({ label, value }: OwnProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        my: "0.5rem",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          margin: "auto 1rem",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            minWidth: "14rem",
            textAlign: "left",
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        component="div"
        sx={{
          fontSize: "1rem",
          fontStyle: "normal",
          lineHeight: "2rem",
          my: "0.75rem",
          minWidth: "14rem",
          textAlign: "right",
        }}
      >
        {" "}
        {value}
      </Typography>
    </Box>
  );
};

export default MetadataRow;

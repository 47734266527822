import { useQuery } from "@tanstack/react-query";

import { userInfo } from "../api/auth";

export const currentUserQueryKey = "current-user-query";

export const getCurrentUserQuery = () => ({
  queryKey: [currentUserQueryKey],
  queryFn: userInfo,
  retry: 0,
  refetchOnWindowFocus: false,
});

export const useCurrentUserQuery = () => useQuery(getCurrentUserQuery());

import React from "react";

import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

const FormControlLabel = (props: FormControlLabelProps) => (
  <MuiFormControlLabel {...props} />
);

export default FormControlLabel;

import { UserDetailsState } from "./userDetailsReducer";
import { UserDetails } from "../../models/user";

export const isPersonalInfoModified = (
  initialData: UserDetails,
  currentData: UserDetailsState
) => {
  const userInfoKeys: (keyof UserDetails)[] = [
    "isActive",
    "email",
    "firstName",
    "lastName",
  ];
  for (const key of userInfoKeys) {
    if (initialData[key] !== currentData[key]) {
      return true;
    }
  }
  return false;
};

import { Location } from "react-router-dom";

import { getQueryParamValue } from "../util/url";

const getClientIdFromNextParamValue = (
  currentLocation: Location
): string | null => {
  const next = getQueryParamValue(currentLocation.search, "next");
  if (!next) return null;
  const absNextUrl = new URL(window.location.origin + next);
  return getQueryParamValue(absNextUrl.search, "client_id");
};

export const getClientIdParamValue = (
  currentLocation: Location
): string | null => {
  return (
    getQueryParamValue(currentLocation.search, "client_id") ||
    getClientIdFromNextParamValue(currentLocation)
  );
};

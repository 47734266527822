import React from "react";

import {
  DispatchType,
  UserDetailsActionType,
  UserDetailsState,
} from "./userDetailsReducer";
import { isPersonalInfoModified } from "./util";
import Box from "../../bp-ui/components/Box";
import Button from "../../bp-ui/components/Button";
import { UserDetails } from "../../models/user";

interface OwnProps {
  initialUserDetails: UserDetails;
  userDetailsState: UserDetailsState;
  dispatch: DispatchType;
}

const EditActions = ({
  initialUserDetails,
  userDetailsState,
  dispatch,
}: OwnProps) => {
  return (
    <Box
      id="action-bar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        maxWidth: "40rem",
        marginTop: "10rem",
      }}
    >
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mx: "0.5rem" }}
        disabled={
          !isPersonalInfoModified(initialUserDetails, userDetailsState) ||
          !userDetailsState.isEmailValid ||
          !userDetailsState.isFirstNameValid ||
          !userDetailsState.isLastNameValid
        }
      >
        Save
      </Button>
      <Button
        type="button"
        variant="contained"
        color="error"
        disabled={!isPersonalInfoModified(initialUserDetails, userDetailsState)}
        sx={{ mx: "0.5rem" }}
        onClick={() => {
          dispatch({
            type: UserDetailsActionType.SET_USER_DETAILS_STATE,
            payload: initialUserDetails,
          });
        }}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default EditActions;

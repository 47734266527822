import React, { ReactNode } from "react";

import FullPageLayout from "./FullPageLayout";
import Box from "../../bp-ui/components/Box";
import { mobileScreenSx } from "../util/screen";

interface OwnProps {
  children: ReactNode;
}

const VerificationPageLayout = ({ children }: OwnProps) => {
  return (
    <FullPageLayout showBrandLogo={false}>
      <Box
        sx={{
          width: "24rem",
          paddingTop: "30%",
          marginX: "auto",
          alignContent: "center",
          ...mobileScreenSx({
            paddingTop: "2rem",
          }),
        }}
      >
        {children}
      </Box>
    </FullPageLayout>
  );
};

export default VerificationPageLayout;

import React from "react";

import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import ArrowBackIcon from "../../bp-ui/icons/ArrowBackIcon";

interface OwnProps {
  currentStep: string;
  numberOfSteps: string;
  decrementStep: () => void;
}

const RegistrationFormStep = ({
  currentStep,
  numberOfSteps,
  decrementStep,
}: OwnProps) => {
  return (
    <Box
      marginBottom="0.5rem"
      fontStyle="normal"
      display="flex"
      justifyContent="space-between"
    >
      <Box sx={{ cursor: "pointer" }} display="flex" onClick={decrementStep}>
        <Box>
          <ArrowBackIcon />
        </Box>
        <Typography color={BPColors.PrimaryLight}>Go Back</Typography>
      </Box>
      <Typography color={BPColors.PrimaryLight}>
        Step {currentStep} of {numberOfSteps}
      </Typography>
    </Box>
  );
};

export default RegistrationFormStep;

import React, { useState } from "react";

import { PasswordResetEmailConfirmation } from "../components/passwordReset/PasswordResetEmailConfirmation";
import { SendPasswordResetEmail } from "../components/passwordReset/SendPasswordResetEmail";
import { ForgotPasswordContext } from "../context/ForgotPasswordContext";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  return (
    <ForgotPasswordContext.Provider
      value={{ email, setEmail, emailSent, setEmailSent }}
    >
      {emailSent ? (
        <PasswordResetEmailConfirmation />
      ) : (
        <SendPasswordResetEmail />
      )}
    </ForgotPasswordContext.Provider>
  );
};

export default ForgotPasswordPage;

export const devApiSpec = {
  openapi: "3.0.3",
  info: {
    description: "This is the internal Identity service documentation.",
    version: "1.0.0",
    title: "Identity Service",
    termsOfService: {},
    contact: {},
    license: {
      name: {},
      url: {},
    },
  },
  schemes: ["https", "http"],
  servers: [
    {
      url: "http://127.0.0.1:8000/",
      description: "Local server for testing",
    },
    {
      url: "https://account.beatportstage.com/",
      description: "Internal staging server for testing",
    },
  ],
  tags: [
    {
      name: "Account creation",
    },
    {
      name: "Authentication",
    },
    {
      name: "Account information",
    },
    {
      name: "Validations",
    },
    {
      name: "Email triggers",
    },
    {
      name: "OAuth",
    },
  ],
  paths: {
    "/identity/v1/register/": {
      post: {
        tags: ["Account creation"],
        summary: "Register user",
        description: "Register new account",
        operationId: "registerUser",
        produces: ["application/json"],
        requestBody: {
          required: "true",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/RegistrationBody",
              },
              examples: {
                "Register user": {
                  value: {
                    email: "georgiana.underwood@beatporttest.com",
                    firstName: "Georgiana",
                    lastName: "Underwood",
                    password: "Beatport@2023",
                    username: "georgiana",
                    cfTurnstileResponse: "XXXX.DUMMY.TOKEN.XXXX",
                  },
                },
              },
            },
          },
        },
        responses: {
          "200": {
            summary: "OK",
            description:
              "OK.\n\nThe session ID is returned in a cookie named `sessionid`. You need to include this cookie in subsequent requests.",
          },
          "400": {
            description: "Bad Request",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/login/": {
      post: {
        tags: ["Authentication"],
        summary: "Login",
        description: "Login with username and password",
        operationId: "loginWithUsernameAndPassword",
        produces: ["application/json"],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserLoginForm",
              },
              examples: {
                "Login with username and password": {
                  value: {
                    password: "beatport",
                    username: "dj",
                  },
                },
              },
            },
          },
        },
        responses: {
          "200": {
            summary: "OK",
            description:
              "OK.\n\nThe session ID is returned in a cookie named `sessionid`. You need to include this cookie in subsequent requests.",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/User",
                },
              },
            },
          },
          "400": {
            description: "Bad Request",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/LoginErrorResponseBody",
                },
              },
            },
          },
          "429": {
            description: "Too Many Requests",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/LoginErrorResponseBody",
                },
              },
            },
          },
          "403": {
            description: "Forbidden",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/LoginErrorResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/logout/": {
      get: {
        tags: ["Authentication"],
        summary: "Logout",
        description: "Logout",
        operationId: "logout",
        security: {
          cookieAuthentication: [],
        },
        responses: {
          "200": {
            description: "OK",
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/": {
      get: {
        tags: ["Account information"],
        summary: "Retrieve current user",
        description: "Retrieve current user information",
        operationId: "retrieveCurrentUser",
        security: {
          cookieAuthentication: [],
        },
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/User",
                },
              },
            },
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/email/": {
      patch: {
        tags: ["Account information"],
        summary: "Update email",
        description: "Update email of the current user",
        operationId: "updateEmail",
        security: {
          cookieAuthentication: [],
        },
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UpdateEmailBody",
              },
              examples: {
                "Update email": {
                  value: {
                    email: "email2@email.com",
                  },
                },
              },
            },
          },
        },
        responses: {
          "204": {
            description: "No Content",
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/profile/": {
      patch: {
        tags: ["Account information"],
        summary: "Update profile",
        description: "Update first and last name",
        operationId: "updateProfile",
        security: {
          cookieAuthentication: [],
        },
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/ProfileBody",
              },
              examples: {
                "Update profile": {
                  value: {
                    firstName: "Jane",
                    lastName: "Doe",
                  },
                },
              },
            },
          },
        },
        responses: {
          "204": {
            description: "No Content",
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/password/": {
      patch: {
        tags: ["Account information"],
        summary: "Update password",
        description: "Update password",
        operationId: "updatePassword",
        security: {
          cookieAuthentication: [],
        },
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/PasswordBody",
              },
              examples: {
                "Update profile": {
                  value: {
                    password: "newpassword",
                  },
                },
              },
            },
          },
        },
        responses: {
          "204": {
            description: "No Content",
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/verify-email/": {
      patch: {
        tags: ["Account information"],
        summary: "Email verification",
        description:
          'After you register, account email has been sent to your email address. In that email there is a link you can click on and confirm email. Link contains token query parameter and that token is used to verify your email.\n\nToken is saved in Redis instance for the environemnt and token for your user follows pattern:\n\n"\\*email_verification: TOKEN VALUE"\n\ni.e. 87f10696-8468-4475-9837-b0cf4ad4c2b9\n\n":1:email_verification:87f10696-8468-4475-9837-b0cf4ad4c2b9"\n\nIn this case token is: 87f10696-8468-4475-9837-b0cf4ad4c2b9\n\nYou can use this to verify your email.\n\nAfter you verify your email log in and check\n\nEmail confirmed field.',
        operationId: "emailVerification",
        parameters: [
          {
            name: "token",
            in: "query",
            schema: {
              type: "string",
              example: "143f0ea6-8829-4a6d-a841-a06ec3b62e40",
            },
          },
          {
            name: "sessionid",
            in: "header",
            schema: {
              type: "string",
              example: "tvv5hlaicndqze8gjvmbogk2d80e7md4",
            },
          },
          {
            name: "x-csrf-token",
            in: "header",
            schema: {
              type: "string",
              example: "rMRdysdHY1gD2SxuejttKWVc3QUVr5GN",
            },
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/VerifyEmailBody",
              },
              examples: {
                "Email verification": {
                  value: {
                    token: "143f0ea6-8829-4a6d-a841-a06ec3b62e40",
                  },
                },
              },
            },
          },
        },
        responses: {
          "200": {
            description: "OK",
          },
          "400": {
            description: "Bad Request",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/me/send-verification-email/": {
      post: {
        tags: ["Email triggers"],
        summary: "Send verification email",
        description: "Send verification email to current user.",
        operationId: "sendVerificationEmail",
        security: {
          cookieAuthentication: [],
        },
        requestBody: {
          required: false,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/SendVerificationEmailBody",
              },
            },
          },
        },
        responses: {
          "204": {
            description: "No Content",
          },
          "401": {
            description: "Unathorized",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/forgot-password/": {
      post: {
        tags: ["Email triggers"],
        summary: "Forgot password",
        description:
          "Send password reset email to current user.\n\nAfter this request has been sent you should see 204 response.\n\nThis will send you an email with the link to reset password but if you want to continue with testing, you will need a token required for that route.",
        operationId: "forgotPassword",
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/ForgotPasswordBody",
              },
              examples: {
                "Forgot password": {
                  value: {
                    email: "test@test.com",
                  },
                },
              },
            },
          },
        },
        responses: {
          "200": {
            description: "No Content",
          },
          "400": {
            description: "Bad Request",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ErrorsResponseBody",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/reset-password/": {
      get: {
        tags: ["Validations"],
        summary: "Reset password: validate token from link",
        description:
          'In the query payload, you need to specify the "token" value. This value is generated by the "Forgot password" route.\n\nIn the deployed environment, when you send a "Forgot password" request, a token is generated and sent to your email address. (If you want email in the local environment, you have to enable pub/sub as described in the README.md file). The email has been sent through Salesforce, and the URL is wrapped so you can\'t read the token.\n\nWe also save the token in Redis so you can retrieve the value from there. Connect to the Redis instance and look for a key with the pattern *password_reset*. You should see something like this: ":1:password_reset:d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51".\n\nIn this case, d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51 is the required token.',
        operationId: "resetPasswordValidateTokenFromLink",
        parameters: [
          {
            name: "token",
            in: "query",
            schema: {
              type: "string",
              required: true,
              example: "04c60101-29d9-4eb9-8bf3-4eddeee15728",
            },
          },
        ],
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    username: {
                      type: "string",
                    },
                  },
                },
              },
            },
          },
          "400": {
            description: "Bad Request",
            content: {
              "text/plain": {
                schema: {
                  type: "string",
                  example: "Token not found",
                },
              },
            },
          },
        },
      },
      post: {
        tags: ["Account information"],
        summary: "Reset password",
        description:
          'Resets user password to the specified value.\n\nIn the payload, you need to specify the "token" value. This value is generated by the "Forgot password" route.\n\nIn the deployed environment, when you send a "Forgot password" request, a token is generated and sent to your email address. (If you want email in the local environment, you have to enable pub/sub as described in the README.md file). The email has been sent through Salesforce, and the URL is wrapped so you can\'t read the token.\n\nWe also save the token in Redis so you can retrieve the value from there. Connect to the Redis instance and look for a key with the pattern *password_reset*. You should see something like this: ":1:password_reset:d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51".\n\nIn this case, d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51 is the required token.',
        operationId: "resetPassword",
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/ResetPasswordBody",
              },
              examples: {
                "Reset password": {
                  value: {
                    password: "Beatport@2023",
                    token: "d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51",
                  },
                },
              },
            },
          },
        },
        responses: {
          "201": {
            description: "Created",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ResetPasswordResponseBody",
                },
              },
            },
          },
          "400": {
            description: "Bad Request",
            content: {
              "text/plain": {
                schema: {
                  type: "string",
                  example: "Token not found",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/register/verify/": {
      get: {
        tags: ["Account information"],
        summary: "Retrieve verification status",
        description: "Retrieve email verification status of current user",
        operationId: "retrieveVerificationStatus",
        security: {
          cookieAuthentication: [],
        },
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    verification_status: {
                      type: "boolean",
                    },
                  },
                },
              },
            },
          },
          "400": {
            description: "Bad Request",
            content: {
              "text/plain": {
                schema: {
                  type: "string",
                  example: "user is no longer authenticated",
                },
              },
            },
          },
        },
      },
    },
    "/identity/v1/user/verify-credentials/": {
      get: {
        tags: ["Validations"],
        summary: "Verify Credentials",
        description: "Verify Credentials - username or email",
        operationId: "verifyCredentials",
        parameters: [
          {
            name: "username",
            in: "query",
            schema: {
              type: "string",
              example: "dj",
            },
            description: "Either 'username' or 'email' is required.",
          },
          {
            name: "email",
            in: "query",
            schema: {
              type: "string",
              example: "email@email.com",
            },
            description: "Either 'username' or 'email' is required.",
          },
        ],
        responses: {
          "200": {
            description: "OK",
          },
          "417": {
            description: "Expectation failed.",
          },
          "400": {
            description: "Bad Request",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    detail: {
                      type: "string",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "/o/authorize/": {
      get: {
        tags: ["OAuth"],
        summary: "Authorization Code Grant Flow",
        description:
          "The start of the authorization code flow.\n\nThis route should be managed by you and should be configured to accept a code URL parameter which you will exchange for an access token.\n\nIf 3rd party authorization is enabled, the user will be asked to allow the client application to access its information.\n\nThe endpoint redirects user to the correct location (specified by *redirect_uri*) after Authorization. If the user is not scoped for the particular app, they will be redirected to a 401 Unathorized page.\n\nThere is no response, only redirection with a code query parameter. The grant token code will be set as a URL param code on the redirect URI.\n\nExample: http://localhost:8000/o/redirect/?**code**=BogqefHC6qZrWexKXbstQNohV2gtS1\n\nExchange grant token code for an access token (or an access token and ID Token by adding *openid* to scopes).",
        operationId: "authorize",
        parameters: [
          {
            name: "response_type",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "code",
            },
            description: 'Must be "code"',
          },
          {
            name: "client_id",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "uG00BrgfobeEVu5KzRZgPI9YNbz7gqa7AJavyUQa",
            },
            description:
              "A unique client_id is provided to the client application",
          },
          {
            name: "code_challenge",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "bzf0Ixhgk5W5narV1fP-toQ3qAUQmGu9-mTmCMWkVfM",
            },
            description: "Client application provides this value",
          },
          {
            name: "code_challenge_method",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "S256",
            },
            description: "Client application provides this value",
          },
          {
            name: "redirect_uri",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "http://localhost:8000/o/redirect/",
            },
            description: "Client application provides this value",
          },
          {
            name: "scope",
            in: "query",
            required: false,
            schema: {
              type: "string",
              example: "app:test user:test openid",
            },
            description:
              'Should include "openid" scope alongside other client application scopes if code should be exchanged for access and ID Token',
          },
        ],
      },
    },
    "/o/token/": {
      post: {
        tags: ["OAuth"],
        summary: "Client Credentials Grant Flow",
        description:
          "An endpoint to provide access and refresh token to the client.\n\nYour OAuth application should have a client id and secret that can be used to obtain an access token.\n\nAnonymous token is used to access and browse our catalog in LoFi previews without the user needing to log in.\n\nAccess tokens last 10 minutes.\n\nID tokens last 30 days.\n\nRefresh tokens last 31 days.\n\nIn order for ID token to be provided:\n\n&nbsp;- all parameters **must** be sent in the request body\n\n&nbsp;- your OAuth application must have an algorithm set",
        operationId: "token",
        requestBody: {
          required: "true",
          description:
            "Request body must be of content type **application/x-www-form-urlencoded**",
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  code: {
                    type: "string",
                    required: true,
                    example: "gXzNFsxm1Q783YppwSYYFSYPHhb31J",
                    description:
                      "Required if grant_type is authorization_code or client_credentials",
                  },
                  client_id: {
                    type: "string",
                    required: true,
                    example: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
                  },
                  client_secret: {
                    type: "string",
                    required: true,
                    example:
                      "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
                  },
                  grant_type: {
                    type: "string",
                    required: true,
                    example: "authorization_code",
                    description:
                      "Can be one of: authorization_code, client_credentials, refresh_token",
                  },
                  redirect_uri: {
                    type: "string",
                    example: "http://localhost:8000/o/redirect/",
                    description: "Required if grant_type is authorization_code",
                  },
                  code_verifier: {
                    type: "string",
                    required: true,
                    example:
                      "DIDAPXYDR9B145M9E1KDGERZDX0OF11IXC966OXJZSVG055KF70Q355NZYYBD63W1M9UO21AWNRXS4LBEDIOF6UZ9DGYQ04QE0HUYW",
                    description:
                      "Required if grant_type is authorization_code - client application provides this value",
                  },
                  refresh_token: {
                    type: "string",
                    example: "NIpWtj6t7OiRKsIH0upAUIqw5617KA",
                    description: "Required if grant_type is refresh_token",
                  },
                },
              },
              examples: {
                authorization_code: {
                  value: {
                    code: "gXzNFsxm1Q783YppwSYYFSYPHhb31J",
                    client_id: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
                    client_secret:
                      "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
                    grant_type: "authorization_code",
                    redirect_uri: "http://localhost:8000/o/redirect/",
                    code_verifier:
                      "DIDAPXYDR9B145M9E1KDGERZDX0OF11IXC966OXJZSVG055KF70Q355NZYYBD63W1M9UO21AWNRXS4LBEDIOF6UZ9DGYQ04QE0HUYW",
                  },
                  summary:
                    "Retrieve Access and Refresh Token (and potentially ID Token)",
                },
                client_credentials: {
                  value: {
                    client_id: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
                    client_secret:
                      "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
                    grant_type: "client_credentials",
                  },
                  summary: "Retrieve Anonymous Token",
                },
                refresh_token: {
                  value: {
                    client_id: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
                    client_secret:
                      "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
                    refresh_token: "YDMBtuM10Fmp0wudVD8avZG0nsf8lL",
                    grant_type: "refresh_token",
                  },
                  summary: "Use Refresh Token to retrive new Access Token",
                },
              },
            },
          },
        },
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/TokenBody",
                },
              },
            },
          },
        },
      },
    },
    "/o/custom-introspect/": {
      get: {
        tags: ["OAuth"],
        summary: "Custom Introspect",
        description:
          "An endpoint for custom access token introspection.\n\nGets the token from the Authorization header.",
        operationId: "customIntrospect",
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CustomIntrospectBody",
                },
              },
            },
          },
        },
      },
    },
    "/o/introspect/": {
      get: {
        tags: ["OAuth"],
        summary: "Introspect",
        description:
          "An endpoint for token introspection based on RFC 7662: [https://tools.ietf.org/html/rfc7662](https://tools.ietf.org/html/rfc7662).\n\nGets the token from the URL parameters.",
        operationId: "introspect1",
        parameters: [
          {
            name: "token",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "mF_9.B5f-4.1JqM",
            },
          },
          {
            name: "client_id",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
            },
          },
          {
            name: "client_secret",
            in: "query",
            required: true,
            schema: {
              type: "string",
              example:
                "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
            },
          },
        ],
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/IntrospectBody",
                },
              },
            },
          },
        },
      },
      post: {
        tags: ["OAuth"],
        summary: "Introspect",
        description:
          "An endpoint for token introspection based on RFC 7662: [https://tools.ietf.org/html/rfc7662](https://tools.ietf.org/html/rfc7662).\n\nGets the token from the body form parameters.",
        operationId: "introspect",
        requestBody: {
          content: {
            "application/x-www-form-urlencoded": {
              schema: {
                type: "object",
                properties: {
                  token: {
                    type: "string",
                    required: true,
                    example: "uH3Po4KXWP4dsY4zgyxH",
                  },
                  client_id: {
                    type: "string",
                    required: true,
                    example: "FshkzMI9mUeRhH0WK6xWCfvzohkEC2juMW3i0KjY",
                  },
                  client_secret: {
                    type: "string",
                    required: true,
                    example:
                      "rRry3GCCnvB64O7xkwLAZefYtJJX967iawzGgtHAjR8iZXP6lLmDUmMp8unPo8haou2L6vHXkRS5exCSQAb0NHfY4ReIETUfh6R6NbdtQZJqQstfka6s0mTGmApiySvu",
                  },
                },
              },
            },
          },
        },
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/IntrospectBody",
                },
              },
            },
          },
        },
      },
    },
    "/o/userinfo/": {
      get: {
        tags: ["OAuth"],
        summary: "User Info",
        description:
          "An endpoint to provide user information.\n\nGets user information using ID Token in Authorization header.",
        operationId: "userinfo",
        responses: {
          "200": {
            description: "OK",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserInfoBody",
                },
              },
            },
          },
        },
      },
    },
  },
  components: {
    schemas: {
      RegistrationBody: {
        type: "object",
        properties: {
          firstName: {
            required: true,
            type: "string",
            minLength: 1,
            pattern: "^(?![\\s\\S]*[\\u{10000}-\\u{10FFFF}])[\\s\\S]*$",
            example: "Georgiana",
          },
          lastName: {
            required: true,
            type: "string",
            minLength: 1,
            pattern: "^(?![\\s\\S]*[\\u{10000}-\\u{10FFFF}])[\\s\\S]*$",
            example: "Underwood",
          },
          username: {
            required: true,
            type: "string",
            pattern: "^[\\w.@+-]+$",
            example: "georgiana",
          },
          password: {
            required: true,
            type: "string",
            minLength: 8,
            example: "beatportpassword",
          },
          email: {
            required: true,
            type: "string",
            pattern:
              "^[a-zA-Z0-9._-]+(\\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
            example: "georgiana.underwood@beatporttest.com",
          },
          cfTurnstileResponse: {
            required: true,
            type: "string",
            example: "XXXX.DUMMY.TOKEN.XXXX",
          },
          appClientId: {
            type: "string",
          },
        },
      },
      UserLoginForm: {
        type: "object",
        properties: {
          password: {
            type: "string",
            required: true,
            example: "beatport",
          },
          username: {
            type: "string",
            required: true,
            example: "dj",
          },
        },
      },
      UpdateEmailBody: {
        type: "object",
        properties: {
          email: {
            type: "string",
            required: true,
            pattern:
              "^[a-zA-Z0-9._-]+(\\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
            example: "email2@email.com",
          },
        },
      },
      ProfileBody: {
        type: "object",
        properties: {
          firstName: {
            type: "string",
            required: true,
            minLength: 1,
            example: "Jane",
          },
          lastName: {
            type: "string",
            required: true,
            minLength: 1,
            example: "Doe",
          },
        },
      },
      PasswordBody: {
        type: "object",
        properties: {
          password: {
            type: "string",
            required: true,
            minLength: 8,
            example: "newpassword",
          },
        },
      },
      VerifyEmailBody: {
        type: "object",
        properties: {
          token: {
            type: "string",
            required: true,
            example: "143f0ea6-8829-4a6d-a841-a06ec3b62e40",
          },
        },
      },
      ForgotPasswordBody: {
        type: "object",
        properties: {
          email: {
            type: "string",
            required: "true",
            example: "test@test.com",
          },
        },
      },
      ResetPasswordBody: {
        type: "object",
        properties: {
          password: {
            type: "string",
            required: "true",
            minLength: 8,
            example: "Beatport@2023",
          },
          token: {
            type: "string",
            required: "true",
            example: "d1c555f5-ecd5-4d6d-b5f9-bf82fac4cf51",
          },
        },
      },
      User: {
        type: "object",
        properties: {
          username: {
            type: "string",
            example: "dj",
          },
          firstName: {
            type: "string",
            example: "John",
          },
          lastName: {
            type: "string",
            example: "Doe",
          },
          email: {
            type: "string",
            example: "email@email.com",
          },
          emailConfirmed: {
            type: "boolean",
            example: true,
          },
        },
      },
      ErrorsResponseBody: {
        type: "object",
        properties: {
          errors: {
            type: "array",
            items: {
              type: "object",
            },
          },
        },
      },
      LoginErrorResponseBody: {
        type: "object",
        properties: {
          message: {
            type: "object",
          },
        },
      },
      ResetPasswordResponseBody: {
        type: "object",
        properties: {
          success: {
            type: "boolean",
          },
          message: {
            type: "string",
          },
        },
      },
      TokenBody: {
        type: "object",
        properties: {
          access_token: {
            type: "string",
            example: "eyJ0eXAiOiAiSldUIiwgImFsZyI6ICJSUzI1NiIsICJraWQi",
          },
          expires_in: {
            type: "integer",
            example: 36000,
          },
          token_type: {
            type: "string",
            example: "Bearer",
          },
          scope: {
            type: "string",
            example: "app:prostore user:dj",
          },
          refresh_token: {
            type: "string",
            example: "bQ0Zqr1USOjY35k59uG69RqM6DHkIF",
          },
        },
      },
      IntrospectBody: {
        type: "object",
        properties: {
          active: {
            type: "boolean",
            example: true,
          },
          scope: {
            type: "string",
            example: "read write",
          },
          exp: {
            type: "integer",
            example: 1644825600,
          },
          client_id: {
            type: "string",
            example: "client123",
          },
          beatport_application_id: {
            type: "integer",
            example: 12345,
          },
          beatport_application_name: {
            type: "string",
            example: "Beatport App",
          },
          beatport_application_app_scope: {
            type: "string",
            example: "app:scope",
          },
          username: {
            type: "string",
            example: "user123",
          },
          user_id: {
            type: "integer",
            example: 1,
          },
          email: {
            type: "string",
            example: "email@email.com",
          },
          email_confirmed: {
            type: "boolean",
            example: true,
          },
          first_name: {
            type: "string",
            example: "first",
          },
          last_name: {
            type: "string",
            example: "last",
          },
          date_joined: {
            type: "string",
            example: "2024-04-03T05:35:48-06:00",
          },
          is_authenticated: {
            type: "boolean",
            example: true,
          },
          is_superuser: {
            type: "boolean",
            example: true,
          },
          is_staff: {
            type: "boolean",
            example: true,
          },
        },
      },
      CustomIntrospectBody: {
        type: "object",
        properties: {
          application_id: {
            type: "integer",
            example: 1,
          },
          user_id: {
            type: "integer",
            example: "1",
          },
          username: {
            type: "string",
            example: "jdoe",
          },
          first_name: {
            type: "string",
            example: "jane",
          },
          last_name: {
            type: "string",
            example: "doe",
          },
          scope: {
            type: "string",
            example: "read write dolphin",
          },
        },
      },
      UserInfoBody: {
        type: "object",
        properties: {
          sub: {
            type: "string",
            example: "dj",
            description: 'This value will be equal to the value in "username".',
          },
          email: {
            type: "string",
            example: "email@email.com",
          },
          username: {
            type: "string",
            example: "dj",
          },
          firstName: {
            type: "string",
            example: "John",
          },
          lastName: {
            type: "string",
            example: "Doe",
          },
          emailConfirmed: {
            type: "boolean",
            example: true,
          },
        },
      },
    },
    securitySchemes: {
      cookieAuthentication: {
        type: "apiKey",
        in: "cookie",
        name: "sessionid",
      },
      cookieAuthorization: {
        type: "xCsrfToken",
        in: "cookie",
        name: "csrftoken",
      },
    },
  },
  externalDocs: {
    description: "Confluence documentation for Identity Service",
    url: "[https://beatport.atlassian.net/wiki/spaces/PE/pages/3037298869/Identity+Service](https://beatport.atlassian.net/wiki/spaces/PE/pages/3037298869/Identity+Service)",
  },
};

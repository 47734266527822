import React from "react";

import { BPGroupLogo } from "../../bp-ui/branding/BPGroupLogo";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  customSx?: CustomSx;
}

export const BrandFooter = ({ customSx }: OwnProps) => {
  return (
    <Box sx={{ marginTop: "2rem", ...customSx }}>
      <BPGroupLogo />
      <Typography
        variant="body2"
        color="primary.light"
        sx={{
          marginTop: "0.5rem",
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
          fontStyle: "normal",
          fontWeight: "400",
          "& span": {
            textDecorationLine: "underline",
          },
        }}
        gutterBottom
      >
        One account for both Beatport and Beatsource.
      </Typography>
    </Box>
  );
};

import React from "react";

import MuiInputAdornment, {
  InputAdornmentProps,
} from "@mui/material/InputAdornment";

const InputAdornment = (props: InputAdornmentProps) => (
  <MuiInputAdornment {...props} />
);

export default InputAdornment;

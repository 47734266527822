import React, { useContext, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { sendForgotPassword } from "../../api/auth";
import { is404ClientError, is429ClientError } from "../../api/httpClient";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { BrandFooter } from "../../components/shared/BrandFooter";
import PageCard from "../../components/shared/PageCard";
import PrimaryButton from "../../components/shared/PrimaryButton";
import SecondaryButtonLink from "../../components/shared/SecondaryButton";
import { ForgotPasswordContext } from "../../context/ForgotPasswordContext";
import { PasswordResetEmailAlertState } from "../../enums/alertStates/passwordResetEmailAlertState";
import Pages from "../../util/pages";
import ErrorAlert from "../shared/ErrorAlert";
import LimitErrorAlert from "../shared/LimitErrorAlert";
import { getSharedQueryParamsString } from "../util/url";

export const PasswordResetEmailConfirmation = () => {
  const { email } = useContext(ForgotPasswordContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [alertState, setAlertState] =
    useState<PasswordResetEmailAlertState | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: async (email: string) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await sendForgotPassword({ email: email });
    },
    onError(error: AxiosError) {
      if (is404ClientError(error)) {
        // Simulate that email was sent successfully even if user doesn't exist
        setAlertState(null);
        return;
      }
      if (is429ClientError(error)) {
        setAlertState(PasswordResetEmailAlertState.Limit);
        return;
      }
      setAlertState(PasswordResetEmailAlertState.Generic);
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    setAlertState(null);
    event.preventDefault();
    mutate(email);
  };

  const onReturn = () => {
    navigate(Pages.home + getSharedQueryParamsString(location, true));
  };

  return (
    <PageCard>
      <Box
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        {!isPending &&
          alertState !== PasswordResetEmailAlertState.Generic &&
          alertState !== PasswordResetEmailAlertState.Limit && (
            <div>
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  lineHeight: "2.5rem",
                  marginBottom: "1rem",
                }}
              >
                Check your email
              </Typography>
              {alertState &&
                alertState !== PasswordResetEmailAlertState.Closed && (
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "1rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "1.5rem",
                      "& span": {
                        fontWeight: "500",
                      },
                    }}
                  >
                    We&apos;ve sent your username and a password reset link to:{" "}
                    <span>
                      <strong>{email}</strong>
                    </span>
                  </Typography>
                )}
            </div>
          )}
      </Box>
      <Box id="password-reset-email-sent" component="form" onSubmit={onSubmit}>
        {alertState === PasswordResetEmailAlertState.Generic && (
          <ErrorAlert
            message="Failed to resend password reset email."
            customSx={{
              marginY: "1.5rem",
              lineHeight: "1rem",
            }}
          />
        )}
        {alertState === PasswordResetEmailAlertState.Limit && (
          <LimitErrorAlert
            customSx={{ marginY: "1.5rem", lineHeight: "1rem" }}
          />
        )}
        <PrimaryButton
          type="submit"
          label="Resend Email"
          isLoading={isPending}
          sx={{ marginBottom: "1.5rem", fontSize: "1rem", fontWeight: "400" }}
        />
        <SecondaryButtonLink onClick={onReturn} label="Return to log in" />
      </Box>
      <BrandFooter />
    </PageCard>
  );
};

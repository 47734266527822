import { httpClient } from "./httpClient";

export const updatePassword = async (password: string) => {
  return httpClient.patch("/identity/v1/me/password/", { password });
};

export const updateProfile = async (firstName: string, lastName: string) => {
  return httpClient.patch("/identity/v1/me/profile/", { firstName, lastName });
};

export const updateEmail = async (email: string) => {
  return httpClient.patch("/identity/v1/me/email/", { email });
};

export const sendVerificationEmail = async () => {
  return httpClient.post("/identity/v1/me/send-verification-email/");
};

import React from "react";

import IconButton from "@mui/material/IconButton";

import Box from "../../bp-ui/components/Box";
import Tooltip from "../../bp-ui/components/Tooltip";
import SyncIcon from "../../bp-ui/icons/SyncIcon";
import { UserDetails } from "../../models/user";
import { UserDetailsState } from "../edit/userDetailsReducer";
import { isPersonalInfoModified } from "../edit/util";

interface OwnProps {
  initialUserDetails: UserDetails;
  userDetailsState: UserDetailsState;
  userSync: () => void;
}

const SyncUserDetails = ({
  initialUserDetails,
  userDetailsState,
  userSync,
}: OwnProps) => {
  const isDisabled = () =>
    isPersonalInfoModified(initialUserDetails, userDetailsState);
  return (
    <Box id="sync-button-area">
      <Tooltip
        title={
          isDisabled()
            ? "Edits must be saved before syncing"
            : "Sync email, first name and last name to BP and BS Person tables, Salesforce, and Recurly."
        }
        disableInteractive={isDisabled()}
      >
        <span>
          <IconButton
            area-label="sync-button"
            sx={{ padding: "0.2rem", marginX: "1rem" }}
            disabled={isDisabled()}
            onClick={userSync}
          >
            <SyncIcon disabled={isDisabled()} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default SyncUserDetails;

import { UserDetails } from "../../models/user";
import {
  basicEmailValidation,
  basicNameValidation,
} from "../util/account-fields-validation";

export enum UserDetailsActionType {
  SET_USER_DETAILS_STATE = "SET_USER_DETAILS_STATE",
  TOGGLE_ENABLED = "TOGGLE_ENABLED",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  CHANGE_EMAIL_VALIDATION = "CHANGE_EMAIL_VALIDATION",
  CHANGE_FIRST_NAME = "CHANGE_FIRST_NAME",
  CHANGE_LAST_NAME = "CHANGE_LAST_NAME",
}

type EmailPayload = {
  value: string;
  isValid: boolean;
  errorMessage: string;
};

type EmailValidationPayload = {
  isValid: boolean;
  errorMessage: string;
};

type PayloadObject = UserDetails | EmailPayload | EmailValidationPayload;

export interface UserDetailsAction {
  type: UserDetailsActionType;
  payload?: string | PayloadObject;
}

export interface UserDetailsState extends UserDetails {
  isEmailValid: boolean;
  invalidEmailMessage: string;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
}

export type DispatchType = React.Dispatch<UserDetailsAction>;

export const userDetailsInitialState: UserDetailsState = {
  id: 0,
  email: "",
  isEmailValid: false,
  invalidEmailMessage: "",
  isActive: false,
  registrationDate: new Date(),
  registrationAppName: "",
  ipAddress: "",
  lastLogin: new Date(),
  recentActivity: new Date(),
  username: "",
  firstName: "",
  isFirstNameValid: true,
  lastName: "",
  isLastNameValid: true,
  emailConfirmed: false,
};

export function userDetailsReducer(
  state: UserDetailsState,
  action: UserDetailsAction
) {
  const { type, payload } = action;

  switch (type) {
    case UserDetailsActionType.SET_USER_DETAILS_STATE: {
      const userDetails = payload as UserDetails;

      return {
        ...state,
        ...userDetails,
        isEmailValid: basicEmailValidation(userDetails.email),
      };
    }
    case UserDetailsActionType.TOGGLE_ENABLED: {
      return {
        ...state,
        isActive: !state.isActive,
      };
    }
    case UserDetailsActionType.CHANGE_EMAIL: {
      const { value, isValid, errorMessage } = payload as EmailPayload;

      return {
        ...state,
        email: value,
        isEmailValid: isValid,
        invalidEmailMessage: errorMessage,
      };
    }

    case UserDetailsActionType.CHANGE_EMAIL_VALIDATION: {
      const { isValid, errorMessage } = payload as EmailValidationPayload;
      return {
        ...state,
        isEmailValid: isValid,
        invalidEmailMessage: errorMessage,
      };
    }

    case UserDetailsActionType.CHANGE_FIRST_NAME: {
      if (typeof payload !== "string") {
        return state;
      }
      return {
        ...state,
        firstName: payload,
        isFirstNameValid: basicNameValidation(payload),
      };
    }

    case UserDetailsActionType.CHANGE_LAST_NAME: {
      if (typeof payload !== "string") {
        return state;
      }
      return {
        ...state,
        lastName: payload,
        isLastNameValid: basicNameValidation(payload),
      };
    }

    default: {
      return state;
    }
  }
}

import React from "react";

import EmailVerify from "../../components/settings/EmailVerify";
import FullPageLayout from "../../components/shared/FullPageLayout";

const EmailVerifyPage = () => {
  return (
    <FullPageLayout>
      <EmailVerify />
    </FullPageLayout>
  );
};

export default EmailVerifyPage;

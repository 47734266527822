import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import GoBack from "./GoBack";
import { is401ClientError, is4xxClientError } from "../../api/httpClient";
import { updateProfile } from "../../api/settings";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { currentUserQueryKey } from "../../queries/useCurrentUser";
import { NAME_NOT_VALID_ERROR_MSG } from "../../util/errorMessages";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import { NAME_UPDATED_MSG } from "../../util/successMessages";
import useAuthCurrentUserQuery from "../../util/useAuthCurrentUserQuery";
import Generic4xxErrorAlert from "../shared/Generic4xxErrorAlert";
import PrimaryButton from "../shared/PrimaryButton";
import SuccessAlert from "../shared/SuccessAlert";
import TextAction from "../shared/TextAction";
import TextField from "../shared/TextField";
import { basicNameValidation } from "../util/account-fields-validation";
import { mobileScreenSx } from "../util/screen";

const ProfileEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  const currentUser = useAuthCurrentUserQuery(navigate, location, queryClient);

  const [firstName, setFirstName] = useState(currentUser?.firstName || "");
  const [firstNameError, setFirstNameError] = useState(false);

  const [lastName, setLastName] = useState(currentUser?.lastName || "");
  const [lastNameError, setLastNameError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [showGenericErrorAlert, setShowGenericErrorAlert] = useState(false);

  const isDisabled = () => {
    return (
      (currentUser?.firstName === firstName &&
        currentUser?.lastName === lastName) ||
      !basicNameValidation(firstName) ||
      !basicNameValidation(lastName)
    );
  };

  const handleSetFirstName = (value: string) => {
    setFirstName(value);

    if (!basicNameValidation(value)) {
      setFirstNameError(true);
      return;
    }

    setFirstNameError(false);
  };

  const handleSetLastName = (value: string) => {
    setLastName(value);

    if (!basicNameValidation(value)) {
      setLastNameError(true);
      return;
    }

    setLastNameError(false);
  };

  const updateProfileMutation = useMutation({
    mutationFn: async () => {
      await updateProfile(firstName, lastName);
    },
    onSuccess: () => {
      setFirstNameError(false);
      setLastNameError(false);
      setShowAlert(true);
      queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
    },
    onError: (error: AxiosError) => {
      if (is401ClientError(error)) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home);
      }
      if (is4xxClientError(error)) {
        setShowGenericErrorAlert(true);
      }
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!basicNameValidation(firstName)) {
      setFirstNameError(true);
      return;
    }

    if (!basicNameValidation(lastName)) {
      setLastNameError(true);
      return;
    }

    setShowGenericErrorAlert(false);
    updateProfileMutation.mutate();
  };

  return (
    <Box
      sx={{
        width: "24rem",
        ...mobileScreenSx({
          width: "100%",
        }),
      }}
    >
      <GoBack onClick={() => navigateTo(Pages.settings)} />
      <Typography
        component="h1"
        variant="h3"
        sx={{
          marginY: "1rem",
        }}
      >
        Edit Name
      </Typography>
      <Typography>Update your first name and last name.</Typography>
      <Box
        id="edit-name"
        component="form"
        onSubmit={onSubmit}
        sx={{ marginY: "2rem" }}
      >
        {showAlert && (
          <SuccessAlert
            message={NAME_UPDATED_MSG}
            customSx={{ marginBottom: "1.5rem" }}
          />
        )}
        {showGenericErrorAlert && (
          <Generic4xxErrorAlert
            customSx={{ marginTop: "1.625rem", marginBottom: "1.5rem" }}
          />
        )}
        <TextField
          id="firstName"
          label="First name"
          value={firstName}
          onChange={handleSetFirstName}
          customSx={{ marginBottom: "1.5rem" }}
          error={firstNameError}
          inputProps={{ maxLength: 150 }}
          helperText={firstNameError ? NAME_NOT_VALID_ERROR_MSG : ""}
        />
        <TextField
          id="lastName"
          label="Last name"
          value={lastName}
          onChange={handleSetLastName}
          error={lastNameError}
          customSx={{ marginBottom: "1.5rem", width: "100%" }}
          inputProps={{ maxLength: 150 }}
          helperText={lastNameError ? NAME_NOT_VALID_ERROR_MSG : ""}
        />
        <PrimaryButton type="submit" label="Save" isDisabled={isDisabled()} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <TextAction text="Cancel" onClick={() => navigateTo(Pages.settings)} />
      </Box>
    </Box>
  );
};

export default ProfileEdit;

import React from "react";

import { handleMouseDownEvent } from "./util";
import Box from "../../bp-ui/components/Box";
import FilledInput from "../../bp-ui/components/FilledInput";
import FormControl from "../../bp-ui/components/FormControl";
import FormHelperText from "../../bp-ui/components/FormHelperText";
import IconButton from "../../bp-ui/components/IconButton";
import InputAdornment from "../../bp-ui/components/InputAdornment";
import InputLabel from "../../bp-ui/components/InputLabel";
import ErrorIcon from "../../bp-ui/icons/ErrorIcon";
import { HighlightOffIcon } from "../../bp-ui/icons/HighlightOffIcon";
import WarningIcon from "../../bp-ui/icons/WarningIcon";
import SxProps from "../../bp-ui/SxProps";
import Theme from "../../bp-ui/Theme";

interface OwnProps {
  value: string;
  error?: boolean;
  helperText?: string;
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  label?: string;
  placeholder?: string;
  isVerified?: boolean;
}

const EmailField = ({
  value,
  onChange,
  error,
  helperText,
  sx,
  label = "Email address",
  placeholder,
  isVerified,
}: OwnProps) => {
  const handleClickClear = () => onChange("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const selectAdornmentIcon = () => {
    if (isVerified !== undefined && !isVerified && value === placeholder) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear username field"
            onMouseDown={handleMouseDownEvent}
            edge="end"
          >
            <WarningIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    if (error) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear username field"
            onMouseDown={handleMouseDownEvent}
            edge="end"
          >
            <ErrorIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    if (value.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear username field"
            onClick={handleClickClear}
            onMouseDown={handleMouseDownEvent}
            edge="end"
          >
            <HighlightOffIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    return undefined;
  };

  return (
    <Box sx={sx}>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor="email">{label}</InputLabel>
        <FilledInput
          id="email"
          type="email"
          error={error}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          endAdornment={selectAdornmentIcon()}
          aria-describedby="email-helper-text"
          inputProps={{ maxLength: 254 }}
        />
      </FormControl>
      {error && helperText && (
        <FormHelperText id="email-helper-text" error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default EmailField;

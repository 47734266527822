import React from "react";

import BPColors from "../../bp-ui/Colors";
import Alert from "../../bp-ui/components/Alert";
import { CustomSx } from "../../models/custom-sx";

interface OwnProps {
  message: string;
  customSx?: CustomSx;
}

const SuccessAlert = ({ message, customSx }: OwnProps) => {
  return (
    <Alert
      sx={{ backgroundColor: BPColors.AlertSuccessBackground, ...customSx }}
    >
      {message}
    </Alert>
  );
};

export default SuccessAlert;

import React from "react";

import moment from "moment";

import MetadataRow from "./MetadataRow";
import Box from "../../bp-ui/components/Box";
import { UserDetailsState } from "../edit/userDetailsReducer";

interface OwnProps {
  userDetailsState: UserDetailsState;
}

const AccessMetadata = ({ userDetailsState }: OwnProps) => {
  const noneDate = "< none >";

  const formatDate = (dateValue: Date) => {
    if (moment(dateValue).isValid()) {
      const formattedDate =
        moment.parseZone(dateValue).format("MMM D, YYYY HH:mm:ss") + " MT";
      return formattedDate;
    } else {
      const formattedDateNone = noneDate;
      return formattedDateNone;
    }
  };

  return (
    <Box marginTop="3rem" sx={{ maxWidth: "30rem" }}>
      <MetadataRow
        label="Last Login:"
        value={formatDate(userDetailsState.lastLogin)}
      />
      <MetadataRow
        label="Recent activity:"
        value={formatDate(userDetailsState.recentActivity)}
      />
      <MetadataRow label="IP address:" value={userDetailsState.ipAddress} />
      <MetadataRow
        label="Registration Date:"
        value={formatDate(userDetailsState.registrationDate)}
      />
      <MetadataRow
        label="Registration App:"
        value={userDetailsState.registrationAppName}
      />
    </Box>
  );
};

export default AccessMetadata;

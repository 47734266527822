import { httpClient } from "./httpClient";
import {
  ResetPasswordBody,
  ResetPasswordLinkBody,
  UpdateUserDetailsBody,
} from "../models/control";
import { UserDetails } from "../models/user";

export const userDetails = async (username: string): Promise<UserDetails> => {
  const resp = await httpClient.get<UserDetails>(
    `/identity/v1/control/user-details/${username}/`
  );

  return resp.data;
};

export const updateUserDetails = async (
  username: string,
  updateUserDetailsBody: UpdateUserDetailsBody
): Promise<UserDetails> => {
  return await httpClient.patch(
    `/identity/v1/control/user-details/${username}/`,
    updateUserDetailsBody
  );
};

export const syncUserDetails = async (
  username: string
): Promise<UserDetails> => {
  return await httpClient.post(
    `/identity/v1/control/user-details/${username}/`
  );
};

export const resetPassword = async (
  id: number,
  resetPasswordBody: ResetPasswordBody
) => {
  return httpClient.patch(
    `/identity/v1/control/reset-password/${id}`,
    resetPasswordBody
  );
};

export const resetPasswordLink = async (
  resetPasswordLinkBody: ResetPasswordLinkBody
) => {
  const resp = await httpClient.post(
    "/identity/v1/control/reset-password-link/",
    resetPasswordLinkBody
  );
  return resp.data;
};

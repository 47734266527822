import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { BaseTheme } from "./bp-ui/BaseTheme";
import { userLoader } from "./components/loaders/currentUserLoader";
import AnonUserOnly from "./components/security/AnonUserOnly";
import { RegisterFlowGuard } from "./components/security/RegisterFlowGuard";
import AppConfig from "./components/shared/AppConfig";
import LoadingContent from "./components/shared/LoadingContent";
import Unauthorized from "./components/shared/Unauthorized";
import EmailVerifiedPage from "./pages/EmailVerified.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import GlobalEditPage from "./pages/GlobalEdit.page";
import LoginPage from "./pages/Login.page";
import NotFoundPage from "./pages/NotFound.page";
import AccountCreationPage from "./pages/registration/AccountCreation.page";
import PasswordCreationPage from "./pages/registration/PasswordCreation.page";
import ProfileCreationPage from "./pages/registration/ProfileCreation.page";
import SignUpPage from "./pages/registration/SignUp.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import AccountSettingsPage from "./pages/settings/AccountSettings.page";
import EmailEditPage from "./pages/settings/EmailEdit.page";
import EmailVerifyPage from "./pages/settings/EmailVerify.page";
import PasswordEditPage from "./pages/settings/PasswordEdit.page";
import ProfileEditPage from "./pages/settings/ProfileEdit.page";
import SwaggerPage from "./pages/Swagger.page";
import ThirdPartyAuthorizationPage from "./pages/ThirdPartyAuthorization.page";
import UpdateEmailNotificationPage from "./pages/UpdateEmailNotificationPage";
import Pages from "./util/pages";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: Pages.home,
    element: <AnonUserOnly />,
    errorElement: <LoadingContent />,
    children: [
      {
        path: Pages.home,
        element: <LoginPage />,
      },
      {
        path: Pages.forgotPassword,
        element: <ForgotPasswordPage />,
      },
      {
        path: Pages.registerStep0,
        element: <RegisterFlowGuard />,
        children: [
          {
            path: "",
            element: <SignUpPage />,
          },
          {
            path: Pages.registerStep1_Password,
            element: <PasswordCreationPage />,
          },
          {
            path: Pages.registerStep2_Profile,
            element: <ProfileCreationPage />,
          },
          {
            path: Pages.registerStep3_Account,
            element: <AccountCreationPage />,
          },
        ],
      },
    ],
  },
  {
    path: Pages.settings,
    element: <AccountSettingsPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.emailEdit,
    element: <EmailEditPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.emailVerify,
    element: <EmailVerifyPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.passwordEdit,
    element: <PasswordEditPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.profileEdit,
    element: <ProfileEditPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.resetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: Pages.emailVerified,
    element: <EmailVerifiedPage />,
  },
  {
    path: Pages.updateEmailNotification,
    element: <UpdateEmailNotificationPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.edit,
    element: <GlobalEditPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.thirdPartyAuthorization,
    element: <ThirdPartyAuthorizationPage />,
    loader: userLoader(queryClient),
    errorElement: <Unauthorized />,
  },
  {
    path: Pages.docs,
    element: <SwaggerPage />,
  },
  { path: "*", element: <NotFoundPage /> },
]);

function App() {
  return (
    <BaseTheme>
      <AppConfig>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppConfig>
    </BaseTheme>
  );
}

export default App;

import React, { ReactNode, useLayoutEffect } from "react";

import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";

interface OwnProps {
  children: ReactNode;
}

export const Layout = ({ children }: OwnProps) => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = BPColors.PrimaryMain;
  });

  return (
    <Box
      sx={{
        minWidth: "20rem",
        textAlign: "center",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: BPColors.PrimaryMain,
      }}
    >
      {children}
    </Box>
  );
};

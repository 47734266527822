import React, { useEffect } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useRegistrationStore } from "../../store/registrationStore";
import Pages from "../../util/pages";

const stepMapping: { [path: string]: number } = {
  [Pages.registerStep1_Password]: 1,
  [Pages.registerStep2_Profile]: 2,
  [Pages.registerStep3_Account]: 3,
};

export const RegisterFlowGuard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { step } = useRegistrationStore();

  useEffect(() => {
    /**
     * This hook is to ensure users cannot access specific registration steps by direct URL navigation.
     */
    if (step < stepMapping[location.pathname]) {
      navigate(Pages.registerStep0);
    }
  }, [step, location, navigate]);

  return <Outlet />;
};

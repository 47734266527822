import React, { ReactNode } from "react";

import { BrandLogo } from "../../components/shared/BrandLogo";
import PageCard from "../../components/shared/PageCard";

interface OwnProps {
  children: ReactNode;
}

const RegistrationLayout = ({ children }: OwnProps) => {
  return (
    <PageCard>
      <BrandLogo />
      {children}
    </PageCard>
  );
};

export default RegistrationLayout;

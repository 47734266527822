import { createContext } from "react";

interface ForgotPasswordContextType {
  email: string;
  setEmail: (value: string) => void;
  emailSent: boolean;
  setEmailSent: (value: boolean) => void;
}

export const ForgotPasswordContext = createContext<ForgotPasswordContextType>({
  email: "",
  setEmail: () => {
    /**/
  },
  emailSent: false,
  setEmailSent: () => {
    /**/
  },
});

export const EMAIL_ALREADY_EXISTS_TRY_LOGIN_ERROR_MSG =
  "Email address already exists, try logging in";
export const EMAIL_ALREADY_EXISTS_ERROR_MSG = "Email address already exists";
export const EMAIL_NOT_VALID_ERROR_MSG = "Please enter a valid email address";

export const USERNAME_ALREADY_EXISTS_ERROR_MSG = "Username already exists.";
export const USERNAME_NOT_VALID_ERROR_MSG =
  "Username must contain only letters, numbers, and @/./+/-/_ characters.";

export const NAME_NOT_VALID_ERROR_MSG =
  "Name cannot be blank nor include special characters.";

export const PASSWORD_DESCRIPTION = "Must be at least 8 characters long";

export const UNAUTHORIZED_ERROR_MSG =
  "You are not authorized to see this page.";
export const USER_NOT_FOUND_ERROR_MSG = "User not found.";

export const GENERIC_ERROR_MSG =
  "Oops, the record skipped and something went wrong. Please try again.";

import React from "react";

import AccountCreation from "../../components/registration/AccountCreation";
import RegistrationLayout from "../../components/registration/RegistrationLayout";

const AccountCreationPage = () => {
  return (
    <RegistrationLayout>
      <AccountCreation />
    </RegistrationLayout>
  );
};

export default AccountCreationPage;

import React from "react";

export const BPGroupLogo = () => {
  return (
    <svg
      width="129"
      height="24"
      viewBox="0 0 129 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_396_884)">
        <path
          d="M86.4553 11.667C86.9269 10.7695 87.5989 10.0691 88.4743 9.5738C89.3496 9.07846 90.3713 8.82812 91.542 8.82812C92.916 8.82812 94.0597 9.19563 94.9675 9.93332C95.8781 10.671 96.4336 11.6643 96.6369 12.9133H94.6938C94.5014 12.165 94.122 11.5791 93.561 11.1557C93 10.7323 92.309 10.5192 91.4905 10.5192C90.7399 10.5192 90.084 10.7003 89.5231 11.0625C88.9594 11.4247 88.5258 11.928 88.2195 12.5698C87.9133 13.2116 87.7588 13.952 87.7588 14.7882C87.7588 15.6244 87.9106 16.3647 88.2114 17.0066C88.5122 17.6484 88.9431 18.1517 89.5068 18.5139C90.0678 18.8761 90.7263 19.0572 91.4743 19.0572C92.4282 19.0572 93.2277 18.7988 93.87 18.2849C94.5122 17.7709 94.8564 17.0572 94.9025 16.141V15.8561H91.5962V14.2316H96.7778V20.647H95.2954L95.0922 18.9719C94.7507 19.4619 94.2656 19.8801 93.6342 20.2289C93.0027 20.5751 92.2575 20.7482 91.393 20.7482C90.29 20.7482 89.3144 20.5006 88.4634 20.0026C87.6125 19.5072 86.9458 18.8068 86.4689 17.9014C85.9919 16.9959 85.7534 15.9599 85.7534 14.7855C85.7534 13.6111 85.9892 12.5618 86.4607 11.6617"
          fill="#696B70"
        />
        <path
          d="M102.52 12.0771V13.7682H101.702C100.94 13.7682 100.39 14.0106 100.049 14.4953C99.7074 14.9799 99.5366 15.5978 99.5366 16.3461V20.6497H97.6965V12.0771H99.3334L99.5366 13.3661C99.786 12.964 100.111 12.6497 100.507 12.4207C100.905 12.1917 101.439 12.0771 102.108 12.0771H102.518H102.52Z"
          fill="#696B70"
        />
        <path
          d="M108.49 18.4155C108.962 17.8855 109.198 17.2011 109.198 16.3622C109.198 15.5233 108.962 14.8416 108.49 14.3116C108.019 13.7816 107.396 13.5153 106.626 13.5153C105.856 13.5153 105.233 13.779 104.767 14.3116C104.301 14.8416 104.068 15.526 104.068 16.3622C104.068 17.1984 104.301 17.8828 104.767 18.4155C105.233 18.9454 105.851 19.2117 106.626 19.2117C107.401 19.2117 108.019 18.9454 108.49 18.4155ZM104.298 20.2077C103.623 19.8456 103.1 19.3316 102.732 18.6658C102.363 18.0027 102.179 17.2357 102.179 16.3622C102.179 15.4887 102.363 14.7404 102.732 14.0773C103.1 13.4141 103.623 12.8975 104.298 12.5273C104.973 12.1571 105.751 11.9734 106.623 11.9734C107.496 11.9734 108.274 12.1571 108.948 12.5273C109.623 12.8948 110.146 13.4115 110.515 14.0773C110.883 14.7404 111.068 15.5047 111.068 16.3622C111.068 17.2197 110.883 18 110.515 18.6658C110.146 19.3316 109.623 19.8429 108.948 20.2077C108.271 20.5699 107.496 20.751 106.623 20.751C105.751 20.751 104.973 20.5699 104.298 20.2077Z"
          fill="#696B70"
        />
        <path
          d="M119.39 12.0771V20.6497H117.772L117.569 19.5605C116.911 20.3541 116.014 20.7509 114.875 20.7509C113.854 20.7509 113.024 20.4393 112.388 19.8135C111.751 19.1877 111.434 18.1837 111.434 16.7989V12.0771H113.274V16.6151C113.274 17.4513 113.444 18.0905 113.786 18.5246C114.127 18.9586 114.631 19.177 115.304 19.177C116.008 19.177 116.558 18.924 116.957 18.4154C117.355 17.9094 117.553 17.2116 117.553 16.3301V12.0771H119.393H119.39Z"
          fill="#696B70"
        />
        <path
          d="M126.42 18.4235C126.892 17.8882 127.127 17.2011 127.127 16.3649C127.127 15.5286 126.892 14.8282 126.42 14.3036C125.948 13.779 125.333 13.518 124.572 13.518C123.81 13.518 123.198 13.7763 122.732 14.2956C122.266 14.8149 122.032 15.4914 122.032 16.3302C122.032 17.1691 122.266 17.8882 122.732 18.4235C123.198 18.9587 123.81 19.2277 124.572 19.2277C125.333 19.2277 125.951 18.9587 126.42 18.4235ZM127.008 12.5033C127.631 12.8549 128.122 13.3635 128.474 14.0267C128.827 14.6924 129.003 15.4701 129.003 16.3622C129.003 17.2543 128.827 18 128.474 18.6658C128.122 19.3289 127.634 19.8429 127.008 20.2051C126.382 20.5673 125.672 20.7484 124.878 20.7484C124.23 20.7484 123.669 20.6312 123.19 20.3968C122.713 20.1625 122.331 19.8429 122.049 19.4408V23.9974H120.209V12.0746H121.81L122.032 13.3476C122.737 12.4314 123.686 11.9734 124.878 11.9734C125.672 11.9734 126.382 12.1492 127.008 12.5007"
          fill="#696B70"
        />
        <path
          d="M14.3631 14.8975C14.3631 13.0919 12.8374 11.6245 10.9621 11.6245C9.08672 11.6245 7.59621 13.1585 7.59621 14.8975C7.59621 16.6365 9.08943 18.2051 10.9621 18.2051C12.8347 18.2051 14.3631 16.6551 14.3631 14.8975ZM17.1734 14.8975C17.1734 18.2743 14.4336 21.0013 10.9621 21.0013C7.49052 21.0013 4.78862 18.3435 4.78862 14.8975C4.78862 13.2783 5.41192 11.8455 6.40108 10.7723L2.20325 14.8975L0 12.7324L4.73713 8.13049C5.3794 7.49933 5.70732 6.68176 5.70732 5.77896V0H8.81301V5.77896C8.81301 7.5526 8.17073 9.05193 6.92141 10.2796L6.78049 10.4154C7.87263 9.42743 9.36585 8.83089 10.9593 8.83089C14.4797 8.83089 17.1707 11.5739 17.1707 14.8975"
          fill="#696B70"
        />
        <path
          d="M24.9566 19.0361H27.5393C28.3089 19.0361 28.8997 18.8843 29.3144 18.578C29.7263 18.2717 29.935 17.8456 29.935 17.2997C29.935 16.7538 29.7236 16.293 29.3062 15.9815C28.8862 15.6699 28.2981 15.5154 27.5393 15.5154H24.9566V19.0387V19.0361ZM24.9566 13.9282H27.4201C28.0867 13.9282 28.607 13.7764 28.9756 13.4755C29.3442 13.1745 29.5285 12.7644 29.5285 12.2398C29.5285 11.7152 29.3442 11.3077 28.9756 11.0121C28.607 10.7165 28.0894 10.57 27.4201 10.57H24.9566V13.9282ZM27.3686 8.96948C28.6721 8.96948 29.6829 9.23579 30.4011 9.77108C31.1192 10.3064 31.4797 11.052 31.4797 12.0081C31.4797 12.7112 31.29 13.2864 30.9106 13.7365C30.5312 14.1865 29.9837 14.4901 29.271 14.6472C31.0488 14.9482 31.9377 15.8669 31.9377 17.4036C31.9377 18.4156 31.5556 19.2118 30.7913 19.7897C30.0271 20.3703 28.935 20.6579 27.5203 20.6579H23V8.96948H27.3659H27.3686Z"
          fill="#696B70"
        />
        <path
          d="M38.9756 15.4461C38.897 14.8229 38.645 14.3356 38.2195 13.9841C37.7967 13.6325 37.2602 13.4594 36.6152 13.4594C36.0135 13.4594 35.4959 13.6405 35.0596 14.0027C34.6233 14.3649 34.3713 14.8469 34.3035 15.4488H38.9756V15.4461ZM32.8509 14.0853C33.2087 13.4221 33.7073 12.9108 34.3469 12.5486C34.9864 12.1865 35.7209 12.0054 36.5474 12.0054C37.374 12.0054 38.1246 12.1731 38.7642 12.506C39.4038 12.8389 39.9078 13.313 40.2764 13.9255C40.645 14.538 40.8347 15.2544 40.8455 16.0799C40.8455 16.3036 40.8293 16.53 40.794 16.7644H34.2358V16.8656C34.2791 17.6112 34.5176 18.2024 34.9485 18.6365C35.3794 19.0706 35.9512 19.289 36.6639 19.289C37.2303 19.289 37.7073 19.1585 38.0921 18.8975C38.477 18.6365 38.7317 18.2664 38.8564 17.787H40.691C40.5312 18.6552 40.1057 19.3662 39.4092 19.9228C38.7127 20.4794 37.8428 20.7564 36.7994 20.7564C35.8943 20.7564 35.103 20.5753 34.4282 20.2131C33.7534 19.8509 33.2331 19.3423 32.8645 18.6845C32.4959 18.0267 32.3116 17.265 32.3116 16.3968C32.3116 15.5287 32.4905 14.7457 32.8455 14.0853"
          fill="#696B70"
        />
        <path
          d="M46.981 16.8496H44.7046C44.1382 16.8496 43.7019 16.9587 43.3957 17.1745C43.0894 17.3928 42.9377 17.7124 42.9377 18.1332C42.9377 18.5007 43.0813 18.7963 43.3713 19.0173C43.6585 19.241 44.0542 19.3502 44.5501 19.3502C45.3089 19.3502 45.9024 19.1505 46.3279 18.7484C46.7507 18.3462 46.9702 17.8136 46.981 17.1452V16.8442V16.8496ZM49.7344 19.0866V20.6551H48.7642C48.1978 20.6551 47.7913 20.538 47.542 20.3036C47.2927 20.0693 47.168 19.731 47.168 19.2863C46.5095 20.2663 45.5528 20.7563 44.2954 20.7563C43.3225 20.7563 42.5393 20.5326 41.9512 20.0879C41.3604 19.6432 41.0677 19.0306 41.0677 18.2504C41.0677 17.3715 41.3848 16.6951 42.019 16.2211C42.6531 15.747 43.5772 15.51 44.7886 15.51H46.981V14.9907C46.981 14.5113 46.8103 14.1358 46.4715 13.8642C46.1328 13.5926 45.6612 13.4541 45.0623 13.4541C44.5285 13.4541 44.0894 13.5686 43.7453 13.7976C43.3984 14.0267 43.1924 14.3302 43.1247 14.7084H41.3225C41.4119 13.8509 41.7967 13.1851 42.477 12.7137C43.1572 12.2424 44.0461 12.0027 45.1463 12.0027C46.3117 12.0027 47.2141 12.2717 47.8482 12.8043C48.4824 13.3369 48.7995 14.1065 48.7995 15.1079V18.482C48.7995 18.8815 48.9919 19.0839 49.3767 19.0839H49.7344V19.0866Z"
          fill="#696B70"
        />
        <path
          d="M50.4553 13.6938H48.9431V12.1066H50.4553V9.71777H52.3063V12.1066H54.3957V13.6938H52.3063V18.1519C52.3063 18.4848 52.374 18.7218 52.5095 18.8629C52.645 19.0014 52.8781 19.0706 53.206 19.0706H54.5989V20.6579H52.832C52.0054 20.6579 51.4011 20.4688 51.0217 20.0879C50.6423 19.7098 50.4526 19.1266 50.4526 18.3356V13.6938H50.4553Z"
          fill="#696B70"
        />
        <path
          d="M61.2791 18.4341C61.7506 17.9015 61.9837 17.2171 61.9837 16.3809C61.9837 15.5447 61.7479 14.8496 61.2791 14.3276C60.8103 13.803 60.1951 13.542 59.4363 13.542C58.6775 13.542 58.065 13.8003 57.6016 14.317C57.1382 14.8363 56.9051 15.51 56.9051 16.3462C56.9051 17.1825 57.1382 17.8988 57.6016 18.4315C58.065 18.9668 58.6775 19.2331 59.4363 19.2331C60.1951 19.2331 60.8103 18.9668 61.2791 18.4315M61.8645 12.53C62.4878 12.8815 62.9729 13.3875 63.3252 14.0506C63.6748 14.7138 63.8536 15.4887 63.8536 16.3809C63.8536 17.273 63.6775 18.0134 63.3252 18.6765C62.9729 19.3396 62.4878 19.8509 61.8645 20.2131C61.2412 20.5753 60.5338 20.7564 59.7398 20.7564C59.0948 20.7564 58.5338 20.6392 58.0569 20.4048C57.5826 20.1705 57.2005 19.8536 56.9187 19.4541V23.9947H55.084V12.1066H56.6802L56.9024 13.3742C57.6043 12.4608 58.5501 12.0054 59.7398 12.0054C60.5311 12.0054 61.2412 12.1811 61.8645 12.53Z"
          fill="#696B70"
        />
        <path
          d="M70.7101 18.4261C71.1789 17.8962 71.4147 17.2171 71.4147 16.3809C71.4147 15.5447 71.1789 14.8656 70.7101 14.3356C70.2385 13.8056 69.6179 13.542 68.8483 13.542C68.0786 13.542 67.4607 13.8056 66.9973 14.3356C66.5339 14.8656 66.3008 15.5473 66.3008 16.3809C66.3008 17.2144 66.5339 17.8988 66.9973 18.4261C67.4607 18.9561 68.0786 19.2198 68.8483 19.2198C69.6179 19.2198 70.2385 18.9561 70.7101 18.4261ZM66.5312 20.2131C65.8564 19.8509 65.3361 19.3396 64.9675 18.6765C64.599 18.0134 64.4147 17.249 64.4147 16.3809C64.4147 15.5127 64.599 14.7644 64.9675 14.1012C65.3361 13.4381 65.8564 12.9241 66.5312 12.5566C67.206 12.1891 67.9784 12.0054 68.851 12.0054C69.7236 12.0054 70.496 12.1891 71.1708 12.5566C71.8456 12.9241 72.3659 13.4381 72.7345 14.1012C73.103 14.7644 73.2873 15.5233 73.2873 16.3809C73.2873 17.2384 73.103 18.0134 72.7345 18.6765C72.3659 19.3396 71.8456 19.8509 71.1708 20.2131C70.496 20.5753 69.7236 20.7564 68.851 20.7564C67.9784 20.7564 67.206 20.5753 66.5312 20.2131Z"
          fill="#696B70"
        />
        <path
          d="M78.7209 12.1064V13.7922H77.9052C77.1464 13.7922 76.5963 14.0345 76.2575 14.5192C75.9188 15.0039 75.748 15.6191 75.748 16.3648V20.6577H73.9133V12.1091H75.5448L75.748 13.3954C75.9973 12.9933 76.3198 12.679 76.7155 12.4527C77.1112 12.2236 77.645 12.1091 78.3117 12.1091H78.7209V12.1064Z"
          fill="#696B70"
        />
        <path
          d="M79.3144 9.71777H81.149V12.1039H83.2574V13.6911H81.149V18.1492C81.149 18.4821 81.2168 18.7191 81.3523 18.8576C81.4878 18.9987 81.7209 19.068 82.0488 19.068H83.4417V20.6552H81.6748C80.8482 20.6552 80.2466 20.4661 79.8726 20.0879C79.4986 19.7098 79.3116 19.1239 79.3116 18.3356V9.72044L79.3144 9.71777Z"
          fill="#696B70"
        />
      </g>
      <defs>
        <clipPath id="clip0_396_884">
          <rect width="129" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";

import EmailEdit from "../../components/settings/EmailEdit";
import FullPageLayout from "../../components/shared/FullPageLayout";

const EmailEditPage = () => {
  return (
    <FullPageLayout>
      <EmailEdit />
    </FullPageLayout>
  );
};

export default EmailEditPage;

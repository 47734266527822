import React, { useState } from "react";

import ResetPassword from "./ResetPassword";
import ResetPasswordLink from "./ResetPasswordLink";
import Box from "../../bp-ui/components/Box";
import Snackbar from "../../bp-ui/components/Snackbar";
import { GENERIC_ERROR_MSG } from "../../util/errorMessages";

interface OwnProps {
  userId: number;
}

const EditPassword = ({ userId }: OwnProps) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarText] = useState("");

  const handleSnackbarClose = () => {
    setShowSnackBar(false);
  };

  const setSnackBarError = () => {
    setShowSnackBar(true);
    setSnackBarText(GENERIC_ERROR_MSG);
  };

  const setSnackBarSuccess = (successMessage: string) => {
    setShowSnackBar(true);
    setSnackBarText(successMessage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "40%",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: "2.5rem",
      }}
    >
      <ResetPassword
        userId={userId}
        setSuccess={setSnackBarSuccess}
        setError={setSnackBarError}
      />
      <ResetPasswordLink userId={userId} setError={setSnackBarError} />
      <Snackbar
        open={showSnackBar}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
      />
    </Box>
  );
};

export default EditPassword;

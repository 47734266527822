import axios, { AxiosError, CreateAxiosDefaults, HttpStatusCode } from "axios";

import { isDevelopmentEnvironment } from "../util/environment";

const getHttpClientConfig = () => {
  const config: CreateAxiosDefaults = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (isDevelopmentEnvironment()) {
    config["baseURL"] = "http://localhost:8000/";
  }

  return config;
};

const httpClient = axios.create(getHttpClientConfig());

export const isHttpClientError = (error: unknown) =>
  error instanceof AxiosError;

export const is4xxClientError = (error: AxiosError) => {
  return (
    error.response &&
    error.response?.status >= 400 &&
    error.response?.status < 500
  );
};

export const is417ClientError = (error: AxiosError) => {
  return error.response?.status == HttpStatusCode.ExpectationFailed;
};

export const is401ClientError = (error: AxiosError) => {
  return error.response?.status === HttpStatusCode.Unauthorized;
};

export const is429ClientError = (error: AxiosError) => {
  return error.response?.status === HttpStatusCode.TooManyRequests;
};

export const is404ClientError = (error: AxiosError) => {
  return error.response?.status === HttpStatusCode.NotFound;
};

export const is403ClientError = (error: AxiosError) => {
  return error.response?.status === HttpStatusCode.Forbidden;
};

export { httpClient };

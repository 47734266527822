import React, { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { resetPassword } from "../api/auth";
import Box from "../bp-ui/components/Box";
import Typography from "../bp-ui/components/Typography";
import { PasswordField } from "../components/account/PasswordField";
import { BrandFooter } from "../components/shared/BrandFooter";
import InvalidVerificationLink from "../components/shared/InvalidVerificationLink";
import LoadingContent from "../components/shared/LoadingContent";
import PageCard from "../components/shared/PageCard";
import PrimaryButton from "../components/shared/PrimaryButton";
import SuccessAlert from "../components/shared/SuccessAlert";
import { basicPasswordValidation } from "../components/util/account-fields-validation";
import { getSharedQueryParamsString } from "../components/util/url";
import { useUserFromResetPasswordToken } from "../queries/reset-password";
import Pages from "../util/pages";

const passwordDescription = "Must be at least 8 characters long";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token") || "";

  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const userFromTokenQuery = useUserFromResetPasswordToken(token);

  const submissionMutation = useMutation({
    mutationFn: async () => {
      await resetPassword({
        token,
        password,
      });
    },

    onSuccess: () => {
      setShowAlert(true);
      setError(false);
      navigate(Pages.settings + getSharedQueryParamsString(location));
    },
    onError: () => {
      setError(true);
    },
  });

  const onPasswordChange = (password: string) => {
    setPassword(password);

    if (password.length === 0) {
      setError(true);
      return;
    }

    setError(false);
  };

  const onSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    const isValid = basicPasswordValidation(password);

    if (!isValid) {
      setError(true);
      return;
    }

    submissionMutation.mutate();
  };

  return userFromTokenQuery.isPending ? (
    <LoadingContent />
  ) : userFromTokenQuery.isSuccess ? (
    <PageCard>
      <Box
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Typography component="h1" variant="h3">
          New password
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: "1rem",
            mb: "0.5rem",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: "400",

            "& span": {
              fontWeight: "500",
            },
          }}
          className="sentry-mask"
        >
          Username: <span>{userFromTokenQuery.data?.username}</span>
        </Typography>
        {showAlert && <SuccessAlert message="Password successfully updated." />}
        <Box id="password-reset-form" component="form" onSubmit={onSubmit}>
          <PasswordField
            id="new-password"
            label="New Password"
            value={password}
            onChange={onPasswordChange}
            error={error}
            helperText={passwordDescription}
            customSx={{
              marginY: "1.5rem",
            }}
          />
          <PrimaryButton
            type="submit"
            label="Reset Password"
            isLoading={
              submissionMutation.isPending || userFromTokenQuery.isPending
            }
          />
        </Box>
      </Box>
      <BrandFooter />
    </PageCard>
  ) : (
    <InvalidVerificationLink
      onResendNewLinkClick={() =>
        navigate(Pages.settings + getSharedQueryParamsString(location))
      }
    />
  );
};

export default ResetPasswordPage;

import React, { useLayoutEffect } from "react";

import { Turnstile } from "@marsidev/react-turnstile";
import type { TurnstileInstance } from "@marsidev/react-turnstile";

import Box from "../../bp-ui/components/Box";
import { TurnstileSize } from "../../enums/turnstile/turnstileSize";
import { TurnstileStatus } from "../../enums/turnstile/turnstileStatus";
import { TurnstileTheme } from "../../enums/turnstile/turnstileTheme";
import { Environment } from "../../util/environment";

interface OwnProps {
  options: {
    action: string;
    theme: TurnstileTheme;
    size: TurnstileSize;
    language: string;
    retryInterval: number;
  };
  setTurnstileToken: (
    value: string,
    error: boolean,
    errorMessage: string
  ) => void;
  setTurnstileStatus: (status: TurnstileStatus) => void;
}

function BeatportTurnstile({
  options,
  setTurnstileToken,
  setTurnstileStatus,
}: OwnProps) {
  const ref = React.useRef<TurnstileInstance>(null);

  useLayoutEffect(() => {
    return () => {
      ref.current?.remove();
    };
  }, []);

  return (
    <Box
      sx={{
        alignContent: "center",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Turnstile
        ref={ref}
        siteKey={Environment.getTurnstileEnvironmentSiteKey()}
        options={options}
        onSuccess={(token) => {
          setTurnstileToken(token, false, ""),
            setTurnstileStatus(TurnstileStatus.Solved);
        }}
        onError={() => setTurnstileStatus(TurnstileStatus.Error)}
        onExpire={() => setTurnstileStatus(TurnstileStatus.Expired)}
      />
    </Box>
  );
}

export default BeatportTurnstile;

import React from "react";

import ErrorAlert from "./ErrorAlert";
import { CustomSx } from "../../models/custom-sx";
import { GENERIC_ERROR_MSG } from "../../util/errorMessages";

interface OwnProps {
  customSx?: CustomSx;
}

const Generic4xxErrorAlert = ({ customSx }: OwnProps) => {
  return <ErrorAlert customSx={{ ...customSx }} message={GENERIC_ERROR_MSG} />;
};

export default Generic4xxErrorAlert;

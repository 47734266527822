import React from "react";

import ProfileCreation from "../../components/registration/ProfileCreation";
import RegistrationLayout from "../../components/registration/RegistrationLayout";

const ProfileCreationPage = () => {
  return (
    <RegistrationLayout>
      <ProfileCreation />
    </RegistrationLayout>
  );
};

export default ProfileCreationPage;

import React from "react";

import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import TextAction from "../shared/TextAction";
import VerificationPageLayout from "../shared/VerificationPageLayout";

interface OwnProps {
  onClick: () => void;
  email: string;
}

const EmailVerifiedContent = ({ onClick, email }: OwnProps) => {
  return (
    <VerificationPageLayout>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            height: "2.5rem",
          }}
        >
          Email verified
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            height: "1.5rem",
            paddingTop: "1rem",
          }}
          className="sentry-mask"
        >
          {email}
        </Typography>
        <TextAction
          text="Close"
          onClick={onClick}
          customSx={{
            paddingTop: "2rem",
            height: "1.5rem",
          }}
        />
      </Box>
    </VerificationPageLayout>
  );
};

export default EmailVerifiedContent;

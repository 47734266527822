import React, { useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "../../api/control";
import Box from "../../bp-ui/components/Box";
import Button from "../../bp-ui/components/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../bp-ui/components/Dialog";
import TextField from "../../bp-ui/components/TextField";
import { ResetPasswordBody } from "../../models/control";
import { PASSWORD_DESCRIPTION } from "../../util/errorMessages";
import { PASSWORD_UPDATED_MSG } from "../../util/successMessages";
import { basicPasswordValidation } from "../util/account-fields-validation";

interface OwnProps {
  userId: number;
  setSuccess: (message: string) => void;
  setError: () => void;
}

const ResetPassword = ({ userId, setSuccess, setError }: OwnProps) => {
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetError, setResetError] = useState(false);

  const mutateResetPassword = useMutation({
    mutationFn: async (data: ResetPasswordBody) => {
      await resetPassword(userId, data);
    },
    onSuccess: () => {
      setResetError(false);
      setSuccess(PASSWORD_UPDATED_MSG);
      handleResetPasswordModal(false);
    },
    onError: () => {
      setResetError(false);
      setError();
      handleResetPasswordModal(false);
    },
  });

  const onResetPasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.currentTarget);
    const newPassword = formData.get("resetPassword") as string;
    if (!basicPasswordValidation(newPassword)) {
      setResetError(true);
      return;
    }
    const resetPasswordBody: ResetPasswordBody = {
      password: newPassword,
    };
    mutateResetPassword.mutate(resetPasswordBody);
  };

  const handleResetPasswordModal = (isOpen: boolean) => {
    setResetError(false);
    setResetPasswordModalOpen(isOpen);
  };

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      <Button
        type="button"
        variant="contained"
        size="large"
        id="resetPasswordButton"
        onClick={() => handleResetPasswordModal(true)}
      >
        Reset password
      </Button>
      <Dialog
        open={resetPasswordModalOpen}
        onClose={() => handleResetPasswordModal(false)}
        PaperProps={{
          component: "form",
          onSubmit: onResetPasswordSubmit,
        }}
      >
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent>
          <TextField
            required
            margin="dense"
            id="resetPasswordInput"
            name="resetPassword"
            fullWidth
            placeholder="New password"
            helperText={PASSWORD_DESCRIPTION}
            error={resetError}
          />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "0.5rem" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mx: "0.5rem" }}
          >
            Confirm
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            sx={{ mx: "0.5rem" }}
            onClick={() => handleResetPasswordModal(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResetPassword;

import React, { useEffect } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import BPColors from "../../bp-ui/Colors";
import { useCurrentUserQuery } from "../../queries/useCurrentUser";
import Pages from "../../util/pages";
import LoadingContent from "../shared/LoadingContent";
import {
  getAuthorizeEndpointUrl,
  getSharedQueryParamsString,
  isAuthorizeUrl,
  navigateToLocation,
} from "../util/url";

const AnonUserOnly = () => {
  const currentUserQuery = useCurrentUserQuery();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !currentUserQuery.isPending &&
      !currentUserQuery.isFetching &&
      !currentUserQuery.isError
    ) {
      const authUrl = getAuthorizeEndpointUrl(location);
      if (authUrl !== null) {
        if (isAuthorizeUrl(authUrl)) {
          navigateToLocation(authUrl);
        } else {
          navigate(authUrl + getSharedQueryParamsString(location, true));
        }
      } else {
        navigate(Pages.settings + getSharedQueryParamsString(location, true));
      }
    }
  }, [currentUserQuery, location, navigate]);

  if (currentUserQuery.isPending && currentUserQuery.isFetching) {
    return <LoadingContent customSx={{ backgroundColor: BPColors.Black }} />;
  }

  return <Outlet />;
};

export default AnonUserOnly;

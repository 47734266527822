import AppConfig from "../models/app-config";

export const isDevelopmentEnvironment = () => {
  return import.meta.env.DEV;
};

declare global {
  interface Window {
    appConfig: AppConfig;
  }
}
export class Environment {
  static getSentryEnvironment() {
    return window.appConfig.environment;
  }
  static getTurnstileEnvironmentSiteKey() {
    return window.appConfig.turnstileSiteKey;
  }
}

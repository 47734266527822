import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import GoBack from "./GoBack";
import { is401ClientError, is429ClientError } from "../../api/httpClient";
import { sendVerificationEmail } from "../../api/settings";
import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import { EmailVerifyAlertState } from "../../enums/alertStates/emailVerifyAlertState";
import { currentUserQueryKey } from "../../queries/useCurrentUser";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import Generic4xxErrorAlert from "../shared/Generic4xxErrorAlert";
import LimitErrorAlert from "../shared/LimitErrorAlert";
import PrimaryButton from "../shared/PrimaryButton";
import SuccessAlert from "../shared/SuccessAlert";
import TextAction from "../shared/TextAction";
import { mobileScreenSx } from "../util/screen";

const EmailVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [alertState, setAlertState] = useState<EmailVerifyAlertState | null>(
    null
  );

  const queryClient = useQueryClient();

  const resendConfirmationEmailMutation = useMutation({
    mutationFn: async () => {
      await sendVerificationEmail();
    },
    onSuccess: () => {
      setAlertState(EmailVerifyAlertState.Success);
    },
    onError: (error: AxiosError) => {
      if (is401ClientError(error)) {
        queryClient.invalidateQueries({ queryKey: [currentUserQueryKey] });
        navigate(Pages.home);
      }
      if (is429ClientError(error)) {
        setAlertState(EmailVerifyAlertState.Limit);
        return;
      }
      setAlertState(EmailVerifyAlertState.Generic);
    },
  });

  const resendConfirmationEmail = () => {
    resendConfirmationEmailMutation.mutate();
  };

  const navigateTo = (path: string) => {
    invalidateQueriesAndNavigateTo(path, queryClient, navigate, location);
  };

  const setEmailEditScreen = () => navigateTo(Pages.emailEdit);

  return (
    <Box
      sx={{
        width: "24rem",
        ...mobileScreenSx({
          width: "100%",
          paddingTop: "2rem",
        }),
      }}
    >
      <GoBack
        onClick={setEmailEditScreen}
        customSx={{ paddingBottom: "1rem" }}
      />
      <Box sx={{ paddingBottom: "1rem" }}>
        <Typography component="h1" variant="h3">
          Verify your email
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          height: "1.5rem",
        }}
      >
        {"We've sent you a link to confirm your email address."}
      </Typography>
      {alertState === EmailVerifyAlertState.Generic && (
        <Generic4xxErrorAlert customSx={{ marginTop: "1.625rem" }} />
      )}
      {alertState === EmailVerifyAlertState.Limit && (
        <LimitErrorAlert customSx={{ marginTop: "1.625rem" }} />
      )}
      {alertState === EmailVerifyAlertState.Success && (
        <SuccessAlert
          customSx={{ marginTop: "1.625rem" }}
          message="Verification email sent."
        />
      )}
      <Box sx={{ paddingY: "2rem" }}>
        <PrimaryButton
          type="button"
          label="Go To Account Settings"
          onClick={() => navigateTo(Pages.settings)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <TextAction
          text="Resend Verification"
          onClick={resendConfirmationEmail}
        />
      </Box>
    </Box>
  );
};

export default EmailVerify;

import React from "react";

import { Link, useLocation } from "react-router-dom";

import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import Pages from "../../util/pages";
import { getSharedQueryParamsString } from "../util/url";

export const SignUpText = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        marginTop: "1rem",
      }}
    >
      <Typography>
        Don’t have an account?{" "}
        <Link
          to={Pages.registerStep0 + getSharedQueryParamsString(location, true)}
          style={{ color: "inherit" }}
        >
          Sign up
        </Link>
      </Typography>
    </Box>
  );
};

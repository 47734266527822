import React from "react";

import { Link, useLocation } from "react-router-dom";

import Box from "../../bp-ui/components/Box";
import Typography from "../../bp-ui/components/Typography";
import Pages from "../../util/pages";
import { getSharedQueryParamsString } from "../util/url";

const ForgotUsernameAndPassword = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1rem",
      }}
    >
      <Link
        to={Pages.forgotPassword + getSharedQueryParamsString(location, true)}
        style={{ color: "inherit" }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Forgot username or password?
        </Typography>
      </Link>
    </Box>
  );
};

export default ForgotUsernameAndPassword;

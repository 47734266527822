import React from "react";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import { devApiSpec } from "../api/docs/openApiSpecification";
import { prodApiSpec } from "../api/docs/prodOpenApiSpecification";
import { isDevelopmentEnvironment } from "../util/environment";

const getApiSpec = () => {
  if (
    window.appConfig.environment === "local" ||
    window.appConfig.environment === "main" ||
    isDevelopmentEnvironment()
  )
    return devApiSpec;
  else return prodApiSpec;
};

const SwaggerPage = () => {
  return (
    <SwaggerUI
      spec={getApiSpec()}
      displayRequestDuration={true}
      filter={false}
    />
  );
};

export default SwaggerPage;

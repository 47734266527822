import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { getSharedQueryParamsString } from "../components/util/url";
import { useCurrentUserQuery } from "../queries/useCurrentUser";
import Pages from "../util/pages";

const selectNavigationUrl = (authenticated: boolean) => {
  return authenticated ? Pages.settings : Pages.home;
};

const NotFoundPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useCurrentUserQuery().data || null;

  useEffect(() => {
    navigate(
      selectNavigationUrl(!!currentUser) +
        getSharedQueryParamsString(location, true)
    );
  }, [currentUser, navigate, location]);

  return <></>;
};

export default NotFoundPage;

import React, { useState } from "react";

import {
  DispatchType,
  UserDetailsActionType,
  UserDetailsState,
} from "./userDetailsReducer";
import BPColors from "../../bp-ui/Colors";
import Box from "../../bp-ui/components/Box";
import FormControlLabel from "../../bp-ui/components/FormControlLabel";
import InputLabel from "../../bp-ui/components/InputLabel";
import Switch from "../../bp-ui/components/Switch";
import TextField from "../../bp-ui/components/TextField";
import Typography from "../../bp-ui/components/Typography";
import { UserDetails } from "../../models/user";
import {
  EMAIL_NOT_VALID_ERROR_MSG,
  NAME_NOT_VALID_ERROR_MSG,
} from "../../util/errorMessages";
import SyncUserDetails from "../account/SyncUserDetails";
import { basicEmailValidation } from "../util/account-fields-validation";

interface OwnProps {
  userDetailsState: UserDetailsState;
  initialUserDetails: UserDetails;
  dispatch: DispatchType;
  userSync: () => void;
}

const PersonalInfo = ({
  userDetailsState,
  initialUserDetails,
  dispatch,
  userSync,
}: OwnProps) => {
  const [showEmailText, setShowEmailText] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = basicEmailValidation(event.target.value);
    dispatch({
      type: UserDetailsActionType.CHANGE_EMAIL,
      payload: {
        value: event.target.value,
        isValid: isValid,
        errorMessage: isValid ? "" : EMAIL_NOT_VALID_ERROR_MSG,
      },
    });
    setShowEmailText(!isValid);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: UserDetailsActionType.CHANGE_FIRST_NAME,
      payload: event.target.value,
    });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UserDetailsActionType.CHANGE_LAST_NAME,
      payload: event.target.value,
    });
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
        <Typography variant="h3" component="h3">
          Global ID {userDetailsState.id}
        </Typography>
        <SyncUserDetails
          initialUserDetails={initialUserDetails}
          userDetailsState={userDetailsState}
          userSync={userSync}
        />
      </Box>
      <Box sx={{ mx: "1rem" }}>
        <FormControlLabel
          sx={{ margin: 0 }}
          value="enabled"
          control={
            <Switch
              color="primary"
              checked={userDetailsState.isActive}
              onChange={() =>
                dispatch({ type: UserDetailsActionType.TOGGLE_ENABLED })
              }
            />
          }
          label="Enabled"
          labelPlacement="start"
        />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
        <Box sx={{ display: "flex", margin: "auto 1rem", width: "8rem" }}>
          <InputLabel htmlFor="username">
            <Typography variant="h6" component="div">
              Username
            </Typography>
          </InputLabel>
        </Box>
        <TextField
          sx={{ minWidth: "20rem", maxWidth: "100%" }}
          id="username"
          value={userDetailsState.username}
          disabled
        />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
        <Box sx={{ display: "flex", margin: "auto 1rem", width: "8rem" }}>
          <InputLabel htmlFor="email">
            <Typography variant="h6" component="div">
              Email
            </Typography>
          </InputLabel>
        </Box>
        <TextField
          sx={{ minWidth: "20rem", maxWidth: "100%" }}
          id="email"
          value={userDetailsState.email}
          onChange={handleEmailChange}
          onFocus={() => setShowEmailText(true)}
        />
        <Box sx={{ mx: "1rem", display: "flex" }}>
          <FormControlLabel
            sx={{ margin: 0 }}
            value="confirmed"
            disabled
            control={
              <Switch
                color="primary"
                checked={userDetailsState.emailConfirmed}
              />
            }
            label="Confirmed"
            labelPlacement="start"
          />
        </Box>
      </Box>

      {showEmailText && (
        <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
          <Box
            sx={{ display: "flex", margin: "auto 1rem", width: "8rem" }}
          ></Box>
          <Typography
            sx={{
              minWidth: "20rem",
              maxWidth: "100%",
              fontSize: "0.75rem",
              color: BPColors.Error,
            }}
          >
            <>
              Email address can not be blank. We will validate the new
              <br /> email address so you must enter a valid address.
              <br />
              <br />
              Updating an email address will send a confirmation email to
              <br /> the new address, and, will also send an email notification
              to
              <br /> the original address.
            </>
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
        <Box sx={{ display: "flex", margin: "auto 1rem", width: "8rem" }}>
          <InputLabel htmlFor="firstName">
            <Typography variant="h6" component="div">
              First name
            </Typography>
          </InputLabel>
        </Box>
        <TextField
          sx={{ minWidth: "20rem", maxWidth: "100%" }}
          id="firstName"
          value={userDetailsState.firstName}
          onChange={handleFirstNameChange}
          error={!userDetailsState.isFirstNameValid}
          helperText={
            userDetailsState.isFirstNameValid ? "" : NAME_NOT_VALID_ERROR_MSG
          }
        />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: "0.5rem" }}>
        <Box sx={{ display: "flex", margin: "auto 1rem", width: "8rem" }}>
          <InputLabel htmlFor="firstName">
            <Typography variant="h6" component="div">
              Last Name
            </Typography>
          </InputLabel>
        </Box>
        <TextField
          sx={{ minWidth: "20rem", maxWidth: "100%" }}
          id="lastName"
          value={userDetailsState.lastName}
          onChange={handleLastNameChange}
          error={!userDetailsState.isLastNameValid}
          helperText={
            userDetailsState.isLastNameValid ? "" : NAME_NOT_VALID_ERROR_MSG
          }
        />
      </Box>
    </Box>
  );
};

export default PersonalInfo;

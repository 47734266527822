import React, { useCallback, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationFormStep from "./RegistrationFormStep";
import TermsOfServiceText from "./TermsOfServiceText";
import { getClientIdParamValue } from "./util";
import { register } from "../../api/auth";
import Box from "../../bp-ui/components/Box";
import Link from "../../bp-ui/components/Link";
import Typography from "../../bp-ui/components/Typography";
import { TurnstileSize } from "../../enums/turnstile/turnstileSize";
import { TurnstileStatus } from "../../enums/turnstile/turnstileStatus";
import { TurnstileTheme } from "../../enums/turnstile/turnstileTheme";
import { RegisterFormData } from "../../models/auth";
import { useRegistrationStore } from "../../store/registrationStore";
import { invalidateQueriesAndNavigateTo } from "../../util/navigation";
import Pages from "../../util/pages";
import BeatportTurnstile from "../account/BeatportTurnstile";
import { BrandFooter } from "../shared/BrandFooter";
import ErrorAlert from "../shared/ErrorAlert";
import PrimaryButton from "../shared/PrimaryButton";
import { getAuthorizeEndpointUrl, navigateToLocation } from "../util/url";

const AccountCreation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    signUpData,
    passwordData,
    profileData,
    accountData,
    setTurnstileToken,
    decrementStep,
  } = useRegistrationStore();

  const [status, setStatus] = useState<TurnstileStatus | null>(null);
  const [showError, setShowError] = useState(false);

  const setTurnstileStatus = useCallback(
    (status: TurnstileStatus) => {
      setStatus(status);
    },
    [status]
  );

  const goBack = () => {
    decrementStep();
    invalidateQueriesAndNavigateTo(
      Pages.registerStep2_Profile,
      queryClient,
      navigate,
      location
    );
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: RegisterFormData) => {
      await register(data);
    },

    onSuccess: () => {
      const authUrl = getAuthorizeEndpointUrl(location);
      if (authUrl !== null) {
        navigateToLocation(authUrl);
      } else {
        invalidateQueriesAndNavigateTo(
          Pages.home,
          queryClient,
          navigate,
          location,
          {
            state: { registrationSuccessful: true },
          }
        );
      }
    },
    onError: (error: AxiosError) => {
      switch (error.response?.status) {
        case HttpStatusCode.Unauthorized:
          setShowError(true);
          break;
        default:
          setShowError(false);
          break;
      }
    },
  });

  const handleCreateAccount = () => {
    mutate({
      username: signUpData.username.value,
      password: passwordData.password.value,
      firstName: profileData.firstName.value,
      lastName: profileData.lastName.value,
      email: signUpData.email.value,
      cfTurnstileResponse: accountData.turnstileToken.value,
      appClientId: getClientIdParamValue(location),
    });
  };

  return (
    <Box className="fade-in">
      {showError && (
        <ErrorAlert
          customSx={{
            marginY: "1.5rem",
            lineHeight: "1rem",
          }}
          message={
            <>
              Unable to register account, please try again. If issue persists
              please{" "}
              <Link
                color="inherit"
                href="https://support.beatport.com/hc/en-us/requests/new"
                target="_blank"
              >
                contact support.
              </Link>
            </>
          }
        />
      )}
      <RegistrationFormStep
        currentStep="3"
        numberOfSteps="3"
        decrementStep={goBack}
      />
      <Typography
        component="h1"
        variant="h3"
        sx={{
          lineHeight: "2.5rem",
          letterSpacing: "-0.013rem",
        }}
      >
        Almost done
      </Typography>
      <Typography
        component="div"
        sx={{
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "1.5rem",
          marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        Look for an email confirmation that will be sent to{" "}
        {<strong className="sentry-mask">{signUpData.email.value}</strong>} to
        confirm your account.
      </Typography>
      <Box
        sx={{
          marginTop: "2rem",
        }}
      >
        <BeatportTurnstile
          options={{
            action: "registration",
            theme: TurnstileTheme.Auto,
            language: "auto",
            size: TurnstileSize.Normal,
            retryInterval: 5000,
          }}
          setTurnstileToken={setTurnstileToken}
          setTurnstileStatus={setTurnstileStatus}
        />
        <TermsOfServiceText />
        <PrimaryButton
          isLoading={isPending}
          isDisabled={status == "solved" ? isPending : true}
          type="submit"
          onClick={handleCreateAccount}
          label="Create Account"
        />
      </Box>
      <BrandFooter />
    </Box>
  );
};

export default AccountCreation;

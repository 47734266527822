import { httpClient } from "./httpClient";
import type {
  ForgotPasswordData,
  LoginFormData,
  RegisterFormData,
  ResetPasswordFormData,
} from "../models/auth";
import { EmailFromToken } from "../models/email";
import { User, UserFromToken } from "../models/user";

export const login = async (loginData: LoginFormData) => {
  return httpClient.post("/identity/v1/login/", loginData);
};

export const userInfo = async (): Promise<User> => {
  const resp = await httpClient.get<User>("/identity/v1/me/");

  return resp.data;
};

export const logout = async () => {
  return httpClient.post("/identity/v1/logout/");
};

export const register = async (registrationData: RegisterFormData) => {
  return httpClient.post("/identity/v1/register/", registrationData);
};

export const sendForgotPassword = async (data: ForgotPasswordData) => {
  return httpClient.post("/identity/v1/forgot-password/", data);
};

export const fetchUserFromResetPasswordToken = async (
  token: string
): Promise<UserFromToken> => {
  const response = await httpClient.get(
    `/identity/v1/reset-password/?token=${token}`
  );
  return response.data;
};

export const resetPassword = async (
  passwordResetForm: ResetPasswordFormData
) => {
  return httpClient.post("/identity/v1/reset-password/", passwordResetForm);
};

export const verifyToken = async (token: string): Promise<EmailFromToken> => {
  const response = await httpClient.patch(`/identity/v1/me/verify-email/`, {
    token: token,
  });
  return response.data;
};

export const reauthorize = async (
  otherParams: string,
  key: string,
  allow: boolean
) => {
  const formData = new URLSearchParams();
  formData.append("allow", allow.toString());
  formData.append("key", key);

  const response = await httpClient.post(
    `/o/authorize/?${otherParams}`,
    formData.toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};
